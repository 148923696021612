<template>
  <div class="nickname">
    <div class="title">
      <div @click="goBack">
        <img src="../imgs/back.png" alt="" />
        <p>个人中心</p>
      </div>
      <span>绑定微信号</span>
    </div>
    <div class="code">
      <div id="qrcode" ref="qrcode"></div>
    </div>
  </div>
</template>

<script>
import request from '@/utils/request'
import QRCode from 'qrcodejs2'
import { wxConfig, baseUrl } from '@/utils/config'
const { appid, redirect_uri } = wxConfig
let timer = null
export default {
  data() {
    return {
      token: this.$store.state.token,
      userInfo: this.$store.state.userInfo,
    }
  },
  beforeDestroy() {
    clearInterval(timer)
    timer = null
  },
  mounted() {
    this.getQrcode()

    timer = setInterval(() => {
      this.getUserBind()
    }, 1000)
  },
  methods: {
    async getUserBind() {
      try {
        const res = await request({
          url: `${baseUrl}/api/user_bind_wx`,
          method: 'get',
          headers: {
            Authorization: `Bearer ${this.token}`,
            'X-Requested-with': 'XMLHttpRequest',
          },
        })

        if (res.data) {
          clearInterval(timer)
          timer = null

          await this.$store.dispatch('getLogin', {
            access_token: this.token,
            user: res,
          })

          this.$message.success('绑定成功')
          this.goBack()
        }
      } catch (e) {
        console.log(e)
      }
    },
    getQrcode() {
      const text = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=${this.userInfo.id}#wechat_redirect`

      this.$refs.qrcode.innerHTML = '' // 清除

      const qrcode = new QRCode('qrcode', {
        width: 212,
        height: 212,
        text: text, // 二维码地址
        colorDark: '#000',
        colorLight: '#fff',
        correctLevel: 3, //纠错码 L:1,M:0,Q:3,H:2
      })
      return qrcode
    },
  },
}
</script>

<style lang="scss" scoped>
.nickname {
  height: 100%;
  background: rgba(245, 245, 245, 0.9);
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  flex-direction: column;

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    height: 116px;
    font-size: 32px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    position: relative;
    div {
      position: absolute;
      top: 0;
      padding-top: 10px;
      left: 60px;
      height: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
      img {
        margin-right: 12px;
        width: 12px;
        height: 23px;
      }
      p {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.25);
        line-height: 25px;
      }
    }
  }

  .code {
    margin: 36px auto;
    width: 244px;
    height: 244px;
    background: rgba(255, 255, 255, 0.93);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    div {
      width: 212px;
      height: 212px;
    }
  }
}
</style>
