import router from './router'
import store from './store' // vuex

router.beforeEach(async (to, from, next) => {
  // set page title
  document.title = `1001chat - ${to.meta.title}`
  const token = store.state.token
  if (token) {
    if (to.path === '/login') {
      next('/')
    } else {
      next()
    }
  } else {
    if (to.path === '/login' || to.path === '/bind' || to.path === '/forgetPwd' || to.path === '/privacy' || to.path === '/agreement') {
      next()
    } else {
      next({ path: '/login' })
    }

  }
})
