<template>
  <div class="nickname">
    <div class="title">
      <div @click="goBack">
        <img src="../imgs/back.png" alt="" />
        <p>个人中心</p>
      </div>
      <span>修改昵称</span>
    </div>
    <div class="content">
      <div class="input">
        <span>昵称</span>
        <input
          type="text"
          v-model.trim="userInfo.nickname"
          placeholder="请设置"
          @keyup.enter="chageUserInfo"
        />
        <p>字数限制为20个字符（含汉字、字母、数字等）</p>
      </div>

      <div class="save" @click="chageUserInfo">保存昵称</div>
    </div>
  </div>
</template>

<script>
import request from '@/utils/request'
import { baseUrl } from '@/utils/config'
export default {
  data() {
    return {
      token: this.$store.state.token,
      userInfo: JSON.parse(JSON.stringify(this.$store.state.userInfo)),
    }
  },
  watch: {
    'userInfo.nickname'(curVal) {
      if (curVal.length > 20) {
        this.userInfo.nickname = String(curVal).slice(0, 20)
      }
    },
  },
  methods: {
    async chageUserInfo() {
      try {
        if(!this.userInfo.nickname){
          this.$message.info('请输入昵称')
          return
        }
        const res = await request({
          url: `${baseUrl}/api/change_user_info`,
          method: 'post',
          headers: {
            Authorization: `Bearer ${this.token}`,
            'X-Requested-with': 'XMLHttpRequest',
          },
          data: {
            nickname: this.userInfo.nickname,
          },
        })
        if (res.code === 200) {
          this.getUserInfo()
          this.$message.success('修改成功')
          this.goBack()
        }
      } catch (e) {
        console.log(e)
      }
    },
    async getUserInfo() {
      try {
        const res = await request({
          url: `${baseUrl}/api/user_info`,
          method: 'get',
          headers: {
            Authorization: `Bearer ${this.token}`,
            'X-Requested-with': 'XMLHttpRequest',
          },
        })
        await this.$store.dispatch('getLogin', {
          access_token: this.token,
          user: res.data,
        })
        this.userInfo = res.data
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.nickname {
  height: 100%;
  background: rgba(245, 245, 245, 0.9);
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  flex-direction: column;

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    height: 116px;
    font-size: 32px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    position: relative;
    div {
      position: absolute;
      top: 0;
      padding-top: 10px;
      left: 60px;
      height: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
      img {
        margin-right: 12px;
        width: 12px;
        height: 23px;
      }
      p {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.25);
        line-height: 25px;
      }
    }
  }

  .content {
    margin: 45px auto;
    width: 370px;
    .input {
      display: flex;
      flex-direction: column;
      span,
      p {
        padding: 0 16px;
        margin-bottom: 11px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
      }
      p {
        margin: 11px 0 0 0;
        color: rgba(0, 0, 0, 0.25);
      }
      input {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        line-height: 20px;
        width: 100%;
        height: 44px;
        background: #ffffff;
        box-shadow: 0px 6px 7px -8px rgba(0, 0, 0, 0.3);
        border: 1px solid #259dff;
        border-radius: 12px;
        min-height: 100%;
        outline: none;
        padding: 0 16px;
        &::-webkit-input-placeholder {
          /* WebKit, Blink, Edge */
          color: #e0d8d8;
        }
        &:-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: #e0d8d8;
        }
        &::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: #e0d8d8;
        }
        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: #e0d8d8;
        }
      }
    }

    .save {
      margin-top: 40px;
      width: 100%;
      height: 44px;
      background: #259dff;
      border-radius: 12px;
      border: 1px solid rgba(255, 255, 255, 0.4);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 22px;
      cursor: pointer;
    }
  }
}
</style>
