<template>
  <div
    class="main"
    @click.stop="toggle = false"
    :class="$route.name === 'Msg' ? '' : 'user-main'"
  >
    <div class="header">
      <div class="header-body">
        <img src="../imgs/logo.png" class="logo" />
        <template
          v-if="
            $route.name !== 'ForgetPwd' &&
            $route.name !== 'Privacy' &&
            $route.name !== 'Agreement'
          "
        >
          <div class="tabs">
            <div class="tab">
              <img src="../imgs/msg.png" @click="getPath(0)" />
            </div>
            <div class="tab">
              <img src="../imgs/user.png" @click="getPath(1)" />
            </div>
            <div
              class="tab-line"
              :style="{
                right: active === 1 ? 0 : '76px',
              }"
            ></div>
          </div>
          <div class="avatar" @click.stop="toggle = !toggle">
            <img
              class="avatar-main"
              :src="userInfo.avatar ? userInfo.avatar : poster"
            />
            <div
              class="logout"
              :style="{
                height: toggle ? '41px' : '0',
              }"
              @click.stop="logout"
            >
              <p>退出登录</p>
              <img src="../imgs/logout.png" alt="" />
            </div>
          </div>
        </template>
      </div>
    </div>

    <div class="body">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"></router-view>
    </div>

    <div class="footer">
      <img src="../imgs/1001.png" alt="" />
    </div>
  </div>
</template>

<script>
import { ossUrl } from '@/utils/config'
export default {
  data() {
    return {
      ossUrl,
      toggle: false,
      poster:
        'https://1001chat.oss-cn-hangzhou.aliyuncs.com/img/qianyi006.jpeg',
      userInfo: this.$store.state.userInfo,
      active: 0,
    }
  },
  watch: {
    '$store.state.userInfo': {
      handler(val) {
        this.userInfo = val
      },
      deep: true,
    },
    '$route.name': {
      handler(val) {
        this.active = val === 'Msg' ? 0 : 1
      },
      deep: true,
    },
  },
  created() {
    this.active = this.$route.name === 'Msg' ? 0 : 1
  },
  methods: {
    getPath(index) {
      this.active = index
      console.log(this.active)
      this.$router.push(index === 0 ? '/msg' : '/user')
    },
    async logout() {
      try {
        await this.$confirm('您确定退出当前账号吗？', '退出登录提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          confirmButtonClass: 'blue',
          type: 'warning',
        })
        await this.$store.dispatch('logout')
        this.$router.replace('/login')
      } catch {
        console.log('取消');
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('../imgs/home-bg.png') no-repeat no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .header {
    width: 100%;
    height: 90px;
    background: url('../imgs/header.png') no-repeat no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .header-body {
      width: 100%;
      padding: 0 210px;
      height: 100%;
      display: flex;
      align-items: center;
      .logo {
        width: 169px;
        height: 33px;
      }

      .tabs {
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: relative;
        .tab {
          margin-left: 40px;
          width: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            height: 30px;
            object-fit: cover;
            cursor: pointer;
          }
        }

        .tab-line {
          position: absolute;
          width: 36px;
          height: 4px;
          background: #ffffff;
          top: 0;
          z-index: 1;
          transition: right 0.3s;
        }
      }

      .avatar {
        margin-left: 80px;
        position: relative;
        .avatar-main {
          width: 36px;
          height: 36px;
          border-radius: 50%;
          border: 1px solid #ffffff;
          object-fit: cover;
          cursor: pointer;
        }
        .logout {
          position: absolute;
          top: 56px;
          left: -72px;
          width: 180px;
          height: 41px;
          background: #ffffff;
          box-shadow: 0px 6px 16px -8px rgba(0, 0, 0, 0.5);
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.85);
          line-height: 20px;
          padding: 0 18px;
          height: 0;
          overflow: hidden;
          transition: height 0.3s;
          cursor: pointer;
          img {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }

  .body {
    flex: 1;
    padding: 32px 80px 52px 80px;
    overflow: hidden;
    width: 100%;
  }

  .footer {
    position: fixed;
    bottom: 22px;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    img {
      width: 123px;
      height: 7px;
    }
  }
}

.user-main {
  background-image: url('../imgs/user-bg.png');
  .header {
    background-image: url('../imgs/user-header.png');
  }
}
</style>
