<template>
  <div class="nickname">
    <h1 class="title">{{ $route.meta.title }}</h1>
    <div class="content">
      <span>尊敬的用户：</span>
      <p>
        欢迎您使用我们的产品和服务！我们深知个人信息对您的重要性，并将竭尽全力保护您的个人信息安全。我们将按国家法律法规要求，恪守权责一致、目的明确、选择同意、最少够用、公开透明、确保安全、主体参与的原则，保护您的个人信息。
      </p>
      <p>
        为了帮助您了解我们通过官方网站收集了您的哪些个人信息，以及您在使用我们官方网站时，我们如何使用、保存、共享和转让这些信息，我们特制定《钱意科技个人信息保护政策》（以下简称“<strong>本政策</strong>”）。请您仔细阅读本政策尤其是<strong>粗体</strong>显示的内容。如您有任何疑问、意见或建议，您可通过本政策第十一节的联系方式与我们联系。
      </p>
      <p>本政策将帮助您了解以下内容：</p>
      <p>1.本政策适用范围</p>
      <p>2.我们如何收集和使用您的个人信息</p>
      <p>3.征得授权同意的例外</p>
      <p>4.我们如何使用Cookie 和同类技术</p>
      <p>5.我们如何存储您的个人信息</p>
      <p>6.我们如何共享、转让、公开披露您的个人信息</p>
      <p>7.我们如何保护您的个人信息</p>
      <p>8.您的权利</p>
      <p>9.未成年人个人信息保护</p>
      <p>10.本政策的变更和修订</p>
      <p>11.如何联系我们</p>
      <p>12.本政策的生效</p>
      <h1>一、本政策适用范围</h1>
      <p>
        本政策适用于我们通过官方网站向您提供的服务。需要特别说明的是，本政策不适用于其他第三方向您提供的服务。例如您通过官方网站链接到的第三方服务或网站。您理解这些服务由第三方独立向您提供，第三方将依照其政策或用户协议单独对您的个人信息处理承担责任。
      </p>
      <p>
        我们可能针对特定线上或线下的服务或产品制定具体服务或产品个人信息保护政策、声明、通知等（以下简称“具体政策”）。在其他具体政策有专门规定的情况下，以该等具体政策为准；该等具体政策未尽之处，以本政策为准。
      </p>
      <h1>二、我们如何收集和使用您的个人信息</h1>
      <p>
        “个人信息”是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。
      </p>
      <p>
        <strong
          >“个人敏感信息”是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。
        </strong>
      </p>
      <p>
        我们收集您的个人信息主要是为了您和其他用户能够更容易和更满意地使用我们的服务（以下简称本服务），而这些信息有助于我们实现这一目标。
      </p>
      <p>
        <strong>您提供的个人信息：</strong
        >如果您创建帐户以使用我们的服务或与我们沟通，我们可能会收集个人信息，如下所示：
      </p>
      <p>
        a.账户信息：当您在我们这里创建帐户时，我们将收集与您的帐户相关的信息，包括您的姓名、联系信息、帐户凭据、交易信息。
      </p>
      <p>
        b.用户内容：当您使用我们的服务时，我们可能会收集您提供给我们服务的输入、文件上传或反馈（“内容”）中包含的个人信息。
      </p>
      <p>
        c.通信信息：如果您与我们通信，我们可能会收集您的姓名、联系信息以及您发送的任何消息的内容。
      </p>
      <p>
        <strong> 我们从您使用服务中自动收到的个人信息：</strong
        >当您访问、使用服务并与之互动时，我们可能会收到有关您访问、使用或互动的以下信息（“技术信息”）：
      </p>
      <p>
        a.日志数据：每当您使用我们的网站时，您的浏览器自动发送的信息（“日志数据”）。日志数据包括您的互联网协议地址、浏览器类型和设置、您请求的日期和时间以及您如何与我们的网站互动。
      </p>
      <p>
        b.使用数据：我们可能会自动收集有关您使用服务的信息，例如您查看或参与的内容类型、您使用的功能和您采取的行动，以及日期和时间、用户代理和版本、计算机或移动设备的类型、计算机连接、IP地址等。
      </p>
      <p>
        c.设备信息：包括您正在使用的设备、操作系统和浏览器的名称。收集的信息可能取决于您使用的设备类型及其设置。
      </p>
      <h1>三、征得授权同意的例外</h1>
      <p>1.与我们履行法律法规规定的义务相关的；</p>
      <p>2.与国家安全、国防安全有关的；</p>
      <p>3.与公共安全、公共卫生、重大公共利益有关的；</p>
      <p>4.与犯罪侦查、起诉、审判和判决执行等有关的；</p>
      <p>
        5.出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；
      </p>
      <p>6.所收集的个人信息是您自行向社会公众公开的；</p>
      <p>
        7.从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；
      </p>
      <p>
        8.用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；
      </p>
      <p>9.为合法的新闻报道所必需的；</p>
      <p>
        10.学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
      </p>
      <p>11.法律、法规或国家标准规定的其他情形。</p>
      <h1>四、我们如何使用 Cookie</h1>
      <p>
        为确保产品正常运转，我们会在您的计算机或移动设备上存储名为Cookie的小数据文件。Cookie通常包含标识符、站点名称以及一些号码和字符。借助于Cookie，产品能够存储您的偏好等数据。我们不会将Cookie
        用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除Cookie。有关详情，请参见
        AboutCookies.org。您可以清除计算机上保存的所有Cookie，大部分网络浏览器都设有阻止Cookie的功能。但如果您这么做，则需要在每一次访问我们的官方网站时亲自更改用户设置。
      </p>
      <h1>五、我们如何存储您的个人信息</h1>
      <p>
        我们在中华人民共和国境内收集和产生的个人信息将存储在中华人民共和国境内。
      </p>
      <p>
        我们仅在本政策所属目的的所必须期间和法律法规要求的时限内存储您的个人信息。
      </p>
      <p>
        如我们停止运营官方网站及相关服务，我们将及时停止继续收集您个人信息的活动，并将以公告的形式进行停止运营通知。同时，对我们存储的个人信息进行删除或匿名化处理。
      </p>
      <h1>六、我们如何共享、转让、公开披露您的个人信息</h1>
      <h2>（一）共享</h2>
      <p>我们不会与任何公司、组织和个人共享您的个人信息，但以下情况除外：</p>
      <p>1.在获取您明示同意的情况下，我们会与其他方共享您的个人信息。</p>
      <p>
        2.我们可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。
      </p>
      <p>
        3.在法律法规允许的范围内，为维护我们、我们的关联公司或合作伙伴、您或其他钱意科技用户或社会公众利益、财产或安全免遭损害而有必要共享您的个人信息。
      </p>
      <p>
        <strong
          >4.与我们的关联公司共享。为便于我们向您提供服务，我们可能会将您的个人信息与我们的关联公司共享。但我们只会共享必要的个人信息，且关联公司对您个人信息的使用受本政策，或经过您授权同意的且对您的个人信息提供与本政策保护水平实质相同的关联公司政策的约束。我们和我们的关联公司均会严格遵守钱意科技的个人信息及数据安全保护制度和政策。
        </strong>
      </p>
      <h2>（二）转让</h2>
      <p>我们不会将您的个人信息转让给其他任何公司、组织或个人，但以下情形除外：</p>
      <p>1.事先获得您明确的同意或授权；</p>
      <p>
        2.根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况进行提供；
      </p>
      <p>
        3.符合与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；
      </p>
      <p>
        <strong
          >4.随着我们业务的发展，我们及我们的关联公司有可能进行合并、收购、资产转让或其他类似的交易。如相关交易涉及到您的个人信息转让，我们会要求新持有您个人信息的公司、组织和个人继续受此政策约束，否则我们将要求该公司、组织和个人重新征得您的授权同意。
        </strong>
      </p>
      <h2>（三）公开披露 </h2>
      <p>我们仅会在以下情况下，公开披露您的个人信息：</p>
      <p>1.获得您明确同意后；</p>
      <p>
        2.基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。
      </p>
      <h1>七、我们如何保护您的个人信息</h1>
      <p>
        我们非常重视您的个人信息安全，已采取符合业界标准的安全防护措施保护您提供的个人信息，以防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。
      </p>
      <p>
        1.我们进行了信息安全等级保护的定级测评，我们按照信息安全等级保护的要求制定了信息安全工作的总体方针和安全策略，建立了覆盖主机、数据、应用、管理等层面的安全管理制度，成立了信息安全管理委员会及信息安全执行委员会，设立了系统平台部为产品安全管理工作的职能部门，明确了安全管理机构内的各个部门和岗位的职责、分工和技能要求，制定了明确的人员录用和离职管理规范。
      </p>
      <p>
        2.我们会对可识别的个人敏感信息进行加密传输和加密存储，加密强度符合安全要求，以确保数据的保密性。我们的应用系统提供了身份鉴别、用户标识唯一性检查、基于角色的访问控制等功能，采用HTTPS安全协议进行通信，设置了最大并发会话连接数，能够对系统服务水平降低到预先规定的最小值进行检测和报警。我们在服务端部署访问控制机制，对可能接触到您个人信息的工作人员采取最小够用授权原则，并定期核查访问人员名单和访问记录。我们的服务器操作系统和数据库系统口令有复杂度要求，采用SSH安全协议进行远程管理，严格限制默认账号的访问权限，并修改了默认口令，审计记录全面并覆盖了所有用户。
      </p>
      <p>
        3.我们存储用户个人信息的服务器系统均为安全加固后的操作系统。我们会对服务器操作进行账号审计及监控。如果发现外部公告有安全问题的服务器操作系统，我们会在第一时间进行服务器安全升级，确保所有服务器系统及应用安全。
      </p>
      <p>
        4.我们为工作人员定期举办个人信息保护相关法律法规培训，以加强工作人员的用户个人隐私保护意识。
      </p>
      <p>
        5.我们制定了网络安全事件应急预案，并调配足够的资源保障确保应急预案的执行。我们每年对应急预案进行了培训和应急事件演练。如果我们的物理、技术或管理防护措施不幸遭到破坏，我们会及时启动应急预案，防止安全事件扩大，按照法律法规的要求上报国家主管机关，并及时采取推送、公告等合理、有效的方式向您告知安全事件的基本情况、可能的影响、已经采取的措施或将要采取的措施等。
      </p>
      <h1>八、您的权利</h1>
      <p>在您使用官方网站期间，您可以通过以下方式访问并管理您的个人信息：</p>
      <p>（一）查询和更正您的个人信息</p>
      <p>您可以通过以下第十一条的联系方式来联系我们查询及更正您的个人信息。</p>
      <p>（二）删除您的个人信息</p>
      <p>以下情况发生时，您有权要求我们删除您的账号注册信息：</p>
      <p>1.我们没有征求您的同意，违法收集了您的个人信息。</p>
      <p>2.我们处理您的个人信息违反了法律法规要求。</p>
      <p>3.我们违反了与您的约定来使用和处理您的个人信息。</p>
      <p>4.您不再使用我们的产品或服务。</p>
      <p>5.我们停止对您提供服务。</p>
      <p>
        若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。
        当您从我们的服务中删除信息后，我们可能不会立即备份系统中删除相应的信息，但会在备份更新时删除这些信息。
      </p>

      <p>（三）撤回您的授权同意</p>
      <p>
        每项业务功能需要一些基本的个人信息才能得以完成。对于额外收集的个人信息的收集和使用，您可以随时给予或收回您的授权同意。您撤回权限不影响我们此前对您个人信息的处理活动，但我们不会继续处理您的个人信息。
      </p>
      <p>（四）注销账户</p>
      <p>
        您随时可注销此前注册的账户。您可以通过以下第十一条的联系方式来联系我们注销您的账户。
      </p>
      <p>（五）获取您的个人信息副本</p>
      <p>您可以通过以下第十一条的联系方式来联系我们获取您的个人信息副本。</p>
      <p>
        在相关技术可行的前提下，如数据接口已匹配，我们还可按您的要求，直接将您的个人信息副本传输给您指定的第三方。
      </p>
      <h1>九、未成年人个人信息保护</h1>
      <p>
        我们的产品、网站和服务主要面向成人。如果没有父母或监护人的同意，不满14周岁的儿童不得使用我们的产品或服务。
      </p>
      <p>
        对于经父母同意而收集儿童个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护儿童所必要的情况下使用或公开披露此信息。
      </p>
      <p>
        如果我们发现自己在未事先获得可证实的父母同意的情况下收集了儿童的个人信息，则会设法尽快删除相关数据。
      </p>
      <h1>十、本政策的变更和修订</h1>
      <p>
        我们的个人信息保护政策可能变更。未经您明确同意，我们不会限制您按照本政策所应享有的权利。
      </p>
      <p>
        对于本政策的重大变更，我们会提供显著的通知。您亦可以随时浏览官方网站查看最新的政策。
      </p>
      <p>本政策所指的重大变更包括但不限于：</p>
      <p>
        1.
        我们的服务模式发生重大变化。如处理个人信息的目的、处理个人信息的类型、个人信息的使用方式等；
      </p>
      <p>2.我们在控制权等方面发生重大变化。如并购重组等引起的所有者变更等；</p>
      <p>3.个人信息共享、转让或公开披露的主要对象发生变化；</p>
      <p>4.您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
      <p>
        5.我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；
      </p>
      <p>6.个人信息安全影响评估报告表明存在高风险时。</p>
      <p>
        7.
        您在此类变更和修订之后继续使用我们的网站，将被视为您同意本政策的变更和修订。
      </p>
      <h1>十一、如何联系我们</h1>
      <p>
        武汉钱意科技有限公司为官方网站的运营主体，亦是您个人信息的控制者。如果您对我们的政策及对您个人信息的处理有任何疑问、意见、建议或投诉，请发送邮件至【1001chat@xr1001.com】，与我们进行联系。
      </p>
      <h1>十二、本政策如何生效</h1>
      <p>本隐私政策版本更新日期为2023年5月22日，将于2023年5月22日正式生效。</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
.nickname {
  height: 100%;
  background: rgba(245, 245, 245, 0.9);
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .title {
    display: flex;
    justify-content: center;
    padding: 40px 0 30px;
    font-size: 32px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .content {
    padding: 30px;
    width: 100%;
    flex: 1;
    overflow: auto;
    white-space: normal;
    word-break: break-all;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 20px;
    h1 {
      font-size: 18px;
      line-height: 40px;
    }
    h2 {
      font-size: 16px;
      line-height: 36px;
    }
    p {
      line-height: 30px;
      text-indent: 2em;
    }
  }
}
</style>
