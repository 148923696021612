import Vue from 'vue'
import Vuex from 'vuex'
import storage from '../utils/storage'

Vue.use(Vuex)

const state = {
  userInfo: storage.get('user') || {},
  token: storage.get('token') || ''
}

const mutations = {
  SET_USERINFO: (state, userInfo) => {
    state.userInfo = userInfo
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  }
}

const actions = {
  getLogin({ commit }, { access_token, user }) {
    return new Promise((resolve) => {
      commit('SET_USERINFO', user)
      commit('SET_TOKEN', access_token)
      storage.set('user', user)
      storage.set('token', access_token)
      resolve()
    })
  },
  logout({ commit }) {
    return new Promise((resolve) => {
      commit('SET_USERINFO', {})
      commit('SET_TOKEN', '')
      storage.remove('user')
      storage.remove('token')
      resolve()
    })
  },
}

const store = new Vuex.Store({
  state,
  mutations,
  actions
})

export default store
