<template>
  <div>
    <div class="main">
      <img src="../imgs/logo.png" class="logo" />
      <div class="login">
        <h1 class="title">欢迎使用</h1>
        <ul class="tabs">
          <li
            class="tab"
            v-for="(item, index) of tabs"
            :key="index"
            @click="changeActive(index)"
          >
            {{ item }}
          </li>
          <span
            class="box"
            :style="{
              transform: `translateX(${90 * active}px)`,
            }"
          ></span>
        </ul>

        <div v-show="active === 0" class="code-body">
          <div class="code">
            <div id="qrcode" ref="qrcode"></div>
          </div>

          <div class="code-tip">
            <img src="../imgs/wechat.png" alt="" />
            <span>微信扫码</span>
            <p>安全快速登录</p>
          </div>
        </div>

        <div v-show="active === 1" class="msg-body">
          <div class="phone">
            <input
              v-model="phone"
              type="text"
              oninput="value=value.replace(/[^\d]/g,'')"
              maxlength="11"
              placeholder="手机号"
              placeholder-class="placeholderStyle"
              @keyup.enter="sendMsg"
            />
          </div>

          <div class="msg">
            <input
              v-model="verification_code"
              type="text"
              maxlength="6"
              oninput="value=value.replace(/[^\d]/g,'')"
              placeholder="请输入内容"
              @keyup.enter="codeLogin"
            />
            <div
              :style="{
                background: time ? '#999999' : '#259DFF',
              }"
              @click="sendMsg"
            >
              {{ time ? `${time}s后重试` : '获取验证码' }}
            </div>
          </div>

          <div class="check">
            <el-radio
              v-model="radio"
              @click.native.prevent.stop="changeRadio"
              :label="true"
            >
              <p>
                我已阅读并同意遵守
                <span @click.prevent.stop="goToLink('agreement')"
                  >《用户服务协议》</span
                >
                和
                <span @click.prevent.stop="goToLink('privacy')"
                  >《隐私策略》</span
                >
              </p>
            </el-radio>
          </div>

          <div class="btn" @click="codeLogin">登录 / 注册</div>
        </div>

        <div v-show="active === 2" class="msg-body">
          <div class="phone">
            <input
              v-model="phone"
              oninput="value=value.replace(/[^\d]/g,'')"
              maxlength="11"
              type="text"
              placeholder="手机号"
              placeholder-class="placeholderStyle"
            />
          </div>

          <div class="phone">
            <input
              v-model="password"
              type="password"
              placeholder="密码"
              placeholder-class="placeholderStyle"
              @keyup.enter="passLogin"
            />
          </div>

          <div class="check">
            <el-radio
              v-model="radio"
              @click.native.prevent.stop="changeRadio"
              :label="true"
            >
              <p>
                我已阅读并同意遵守
                <span @click.prevent.stop="goToLink('agreement')"
                  >《用户服务协议》</span
                >
                和
                <span @click.prevent.stop="goToLink('privacy')"
                  >《隐私策略》</span
                >
              </p>
            </el-radio>
          </div>

          <div class="btn" @click="passLogin">登录</div>

          <div class="forget">
            <span @click="$router.push({ name: 'ForgetPwd' })">忘记密码</span>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <img src="../imgs/1001.png" alt="" />
    </div>
  </div>
</template>

<script>
import request from '@/utils/request'
import { baseUrl } from '@/utils/config'
// import QRCode from 'qrcodejs2'
let msgTimer = null
const regPhone = /^1[3-9]\d{9}$/
export default {
  data() {
    return {
      tabs: ['快捷登录', '验证码登录', '密码登录'],
      active: 0,
      url: '',
      scene_id: '',
      timer: null,
      radio: false,
      value: '',
      phone: undefined,
      verification_key: '',
      verification_code: '',
      password: '',
      time: 0,
      copyPhone: '',
    }
  },
  watch: {
    '$route.query.code'(val){
      if(val){
        this.openLogin(val)
      }
    }
  },
  mounted() {
    if(this.$route.query.code){
      this.openLogin(this.$route.query.code)
    }
    this.getQrCode()
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
    clearInterval(msgTimer)
    msgTimer = null
  },
  methods: {
    async openLogin(code){
      try {
        const res = await request({
          url: `${baseUrl}/api/open_platform_login`,
          method: 'post',
          data: {
            code
          },
          headers: {
            'X-Requested-with': 'XMLHttpRequest',
          },
        })

        if (res.code === 200) {
          this.access_token = res.data.access_token
          this.user = res.data.user
          await this.$store.dispatch('getLogin', {
            access_token: res.data.access_token,
            user: res.data.user,
          })
          this.$router.replace('/')
          clearInterval(this.timer)
          this.timer = null
          this.url = ''
          this.sceneId = ''
          //登录成功，处理登录成功逻辑
          this.$message.success('登录成功')
        }
      } catch (e) {
        console.log(e)
      }
    },
    goToLink(url) {
      window.open(`${location.origin}/#/${url}`, '_blank')
    },
    async passLogin() {
      try {
        if (!this.radio) {
          this.$message.info('请先阅读并同意用户服务协议和隐私策略')
          return
        }
        if (!this.phone) {
          this.$message.info('请输入手机号')
          return
        }
        if (!this.password) {
          this.$message.info('请输入密码')
          return
        }

        const res = await request({
          url: `${baseUrl}/api/pass_login`,
          method: 'post',
          headers: {
            'X-Requested-with': 'XMLHttpRequest',
          },
          data: {
            phone: this.phone,
            password: this.password,
          },
        })
        if (res.code === 200) {
          this.access_token = res.data.access_token
          this.user = res.data.user
          await this.$store.dispatch('getLogin', {
            access_token: res.data.access_token,
            user: res.data.user,
          })
          this.$router.replace('/')
        }
      } catch (e) {
        console.log(e)
      }
    },
    changeRadio() {
      this.radio = !this.radio
      console.log(this.radio)
    },
    async sendMsg() {
      try {
        if (this.time) {
          return
        }
        if (!this.phone) {
          this.$message.info('请输入手机号')
          return
        }
        if (!regPhone.test(this.phone)) {
          this.$message.info('手机号不合法')
          return
        }

        this.time = 60
        const res = await request({
          url: `${baseUrl}/api/sendSms`,
          method: 'post',
          headers: {
            'X-Requested-with': 'XMLHttpRequest',
          },
          data: {
            phone: this.phone,
          },
        })
        if (res.code === 200) {
          this.copyPhone = JSON.parse(JSON.stringify(this.phone))
          this.verification_key = res.data.key
          msgTimer = setInterval(() => {
            this.time--
            if (this.time <= 0) {
              clearInterval(msgTimer)
              msgTimer = null
            }
          }, 1000)
        }
      } catch (e) {
        console.log(e)
      }
    },
    async codeLogin() {
      try {
        if (!this.radio) {
          this.$message.info('请先阅读并同意用户服务协议和隐私策略')
          return
        }

        if (!this.phone) {
          this.$message.info('请输入手机号')
          return
        }

        if (!regPhone.test(this.phone)) {
          this.$message.info('手机号不合法')
          return
        }

        if (this.phone !== this.copyPhone) {
          this.$message.info('手机号不为发送验证码手机号')
          return
        }

        if (!this.verification_code) {
          this.$message.info('请输入验证码')
          return
        }

        const res = await request({
          url: `${baseUrl}/api/login`,
          method: 'post',
          headers: {
            'X-Requested-with': 'XMLHttpRequest',
          },
          data: {
            phone: this.phone,
            verification_key: this.verification_key,
            verification_code: this.verification_code,
          },
        })
        if (res.code === 200) {
          this.access_token = res.data.access_token
          this.user = res.data.user
          await this.$store.dispatch('getLogin', {
            access_token: res.data.access_token,
            user: res.data.user,
          })
          this.$router.replace('/')
        }
      } catch (e) {
        console.log(e)
      }
    },
    async getQrCode() {
      // try {
      //   const res = await request({
      //     url: `${baseUrl}/api/qrcode`,
      //     method: 'get',
      //     headers: {
      //       'X-Requested-with': 'XMLHttpRequest',
      //     },
      //   })
      //   if (res.code === 200) {
      //     this.url = res.data.url
      //     this.scene_id = res.data.scene_id
      //     this.timer = setInterval(this.getUserByScene, 1000)
      //     this.$nextTick(() => {
      //       this.getQrcode(this.url)
      //     })
      //   }
      //   console.log(res)
      // } catch (e) {
      //   console.log(e)
      // }
      let href = 'data:text/css;base64,LmltcG93ZXJCb3gge3dpZHRoOiAyMTJweDtoZWlnaHQ6MjEycHg7fQouaW1wb3dlckJveCAudGl0bGUge2Rpc3BsYXk6IG5vbmU7fQouaW1wb3dlckJveCAucXJjb2RlIHt3aWR0aDogMjEycHg7aGVpZ2h0OjIxMnB4O2JvcmRlcjpub25lO21hcmdpbjowfQouaW1wb3dlckJveCAuaW5mbyB7ZGlzcGxheTogbm9uZTt9'
      new window.WxLogin({
        self_redirect: false,
        id: 'qrcode',
        appid: 'wx7064490126cafc9b',
        scope: 'snsapi_login',
        redirect_uri: encodeURIComponent(`https://1001chat.com/#/login`),
        href
      })
    },
    // getQrcode(text) {
    //   this.$refs.qrcode.innerHTML = '' // 清除
    //   const qrcode = new QRCode('qrcode', {
    //     width: 212,
    //     height: 212,
    //     text: text, // 二维码地址
    //     colorDark: '#000',
    //     colorLight: '#fff',
    //   })
    //   return qrcode
    // },

    //根据 sceneId 轮询查询用户信息，判断用户是否登录成功
    async getUserByScene() {
      if (!this.scene_id) return
      try {
        const res = await request({
          url: `${baseUrl}/api/get_user_by_scene_id`,
          method: 'post',
          data: {
            scene_id: this.scene_id,
          },
          headers: {
            'X-Requested-with': 'XMLHttpRequest',
          },
        })

        if (res.code === 200) {
          this.access_token = res.data.access_token
          this.user = res.data.user
          await this.$store.dispatch('getLogin', {
            access_token: res.data.access_token,
            user: res.data.user,
          })
          this.$router.replace('/')
          clearInterval(this.timer)
          this.timer = null
          this.url = ''
          this.sceneId = ''
          //登录成功，处理登录成功逻辑
          this.$message.success('登录成功')
        }
      } catch (e) {
        console.log(e)
      }
    },
    changeActive(index) {
      this.active = index
      if (index !== 0) {
        clearInterval(this.timer)
        this.timer = null
      } else {
        this.timer = setInterval(this.getUserByScene, 1000)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('../imgs/bg.png') no-repeat no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  -moz-user-select: none; /*Firefox私有属性 */
  -webkit-user-select: none; /*WebKit内核私有属性 */
  -ms-user-select: none; /*IE私有属性(IE10及以后)*/
  -khtml-user-select: none; /*KHTML内核私有属性 */
  -o-user-select: none; /*Opera私有属性 */
  user-select: none; /*CSS3属性 */

  .logo {
    margin-right: 228px;
    width: 314px;
    height: 60px;
  }

  .login {
    width: 440px;
    height: 540px;
    background: rgba(255, 255, 255, 0.3);
    box-shadow: 0px 14px 32px -10px rgba(0, 0, 0, 0.06);
    border-radius: 24px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(8px);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 48px 80px 0 80px;

    .title {
      margin-bottom: 34px;
      font-size: 32px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 45px;
    }

    .tabs {
      display: flex;
      align-items: center;
      position: relative;
      width: 100%;
      height: 36px;
      background: rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      border: 1px solid rgba(255, 255, 255, 0.2);

      .tab {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 20px;
        cursor: pointer;
      }

      .box {
        position: absolute;
        top: 3px;
        left: 3px;
        width: 90px;
        height: calc(100% - 6px);
        background: rgba(255, 255, 255, 0.2);
        border-radius: 10px;
        transition: transform 0.3s;
      }
    }

    .code-body {
      margin-top: 26px;

      .code {
        width: 244px;
        height: 244px;
        background: rgb(255, 255, 255);
        border-radius: 12px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px;
        div {
          width: 100%;
          height: 100%;
        }
      }

      .code-tip {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 22px;
          height: 20px;
        }
        span {
          margin: 0 10px 0 4px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #37fc95;
          line-height: 22px;
        }
        p {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 22px;
        }
      }
    }

    .msg-body {
      width: 100%;
      // overflow: hidden;
      padding-top: 20px;
      input {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        line-height: 20px;
        width: 100%;
        height: 44px;
        background: #ffffff;
        box-shadow: 0px 6px 16px -8px rgba(0, 0, 0, 0.5);
        border-radius: 12px;
        min-height: 100%;
        outline: none;
        border: none;
        padding: 0 16px;
        &::-webkit-input-placeholder {
          /* WebKit, Blink, Edge */
          color: #e0d8d8;
        }
        &:-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: #e0d8d8;
        }
        &::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: #e0d8d8;
        }
        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: #e0d8d8;
        }
      }

      .phone {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        width: 100%;
        height: 44px;
      }

      .msg {
        margin-bottom: 22px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        input {
          flex: 1;
          margin-right: 12px;
        }
        div {
          width: 101px;
          height: 44px;
          background: #259dff;
          border-radius: 12px;
          border: 1px solid rgba(255, 255, 255, 0.4);
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 20px;
          cursor: pointer;
        }
      }

      .check {
        display: flex;
        /deep/ .el-radio {
          // margin-top: 3px;

          display: flex;

          .el-radio__input {
            margin-top: 3px;
            margin-right: 8px;
          }
          .el-radio__label {
            padding: 0;
            p {
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffffff;
              line-height: 17px;
              white-space: normal;
              cursor: default;
              span {
                text-decoration: underline;
                cursor: pointer;
              }
            }
          }
        }
      }

      .btn {
        margin-top: 40px;
        width: 100%;
        height: 44px;
        background: #259dff;
        border-radius: 12px;
        border: 1px solid rgba(255, 255, 255, 0.4);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 22px;
        cursor: pointer;
      }

      .forget {
        margin-top: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 17px;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}

.footer {
  position: fixed;
  bottom: 22px;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  img {
    width: 123px;
    height: 7px;
  }
}
</style>
