var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main",class:_vm.$route.name === 'Msg' ? '' : 'user-main',on:{"click":function($event){$event.stopPropagation();_vm.toggle = false}}},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"header-body"},[_c('img',{staticClass:"logo",attrs:{"src":require("../imgs/logo.png")}}),(
          _vm.$route.name !== 'ForgetPwd' &&
          _vm.$route.name !== 'Privacy' &&
          _vm.$route.name !== 'Agreement'
        )?[_c('div',{staticClass:"tabs"},[_c('div',{staticClass:"tab"},[_c('img',{attrs:{"src":require("../imgs/msg.png")},on:{"click":function($event){return _vm.getPath(0)}}})]),_c('div',{staticClass:"tab"},[_c('img',{attrs:{"src":require("../imgs/user.png")},on:{"click":function($event){return _vm.getPath(1)}}})]),_c('div',{staticClass:"tab-line",style:({
              right: _vm.active === 1 ? 0 : '76px',
            })})]),_c('div',{staticClass:"avatar",on:{"click":function($event){$event.stopPropagation();_vm.toggle = !_vm.toggle}}},[_c('img',{staticClass:"avatar-main",attrs:{"src":_vm.userInfo.avatar ? _vm.userInfo.avatar : _vm.poster}}),_c('div',{staticClass:"logout",style:({
              height: _vm.toggle ? '41px' : '0',
            }),on:{"click":function($event){$event.stopPropagation();return _vm.logout.apply(null, arguments)}}},[_c('p',[_vm._v("退出登录")]),_c('img',{attrs:{"src":require("../imgs/logout.png"),"alt":""}})])])]:_vm._e()],2)]),_c('div',{staticClass:"body"},[_c('keep-alive',[(_vm.$route.meta.keepAlive)?_c('router-view'):_vm._e()],1),(!_vm.$route.meta.keepAlive)?_c('router-view'):_vm._e()],1),_vm._m(0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer"},[_c('img',{attrs:{"src":require("../imgs/1001.png"),"alt":""}})])
}]

export { render, staticRenderFns }