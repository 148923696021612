<template>
  <div class="phone">
    <h1 class="title">
      <div @click="goBack">
        <img src="../imgs/back.png" alt="" />
        <p>个人中心</p>
      </div>
      <span>绑定手机号</span>
    </h1>
    <div class="content">
      <div class="input">
        <span>请输入手机号</span>
        <input
          type="text"
          oninput="value=value.replace(/[^\d]/g,'')"
          maxlength="11"
          placeholder="请输入"
          v-model="userInfo.phone"
          @keyup.enter="sendMsg"
        />
      </div>

      <div class="input input2">
        <input
          type="text"
          maxlength="6"
          oninput="value=value.replace(/[^\d]/g,'')"
          v-model="verification_code"
          placeholder="请输入"
          @keyup.enter="chagePhone"
        />
        <div
          :style="{
            background: time ? '#999999' : '#259DFF',
          }"
          @click="sendMsg"
        >
          {{ time ? `${time}s后重试` : '获取验证码' }}
        </div>
      </div>

      <div class="save" @click="chagePhone">绑定手机号</div>
    </div>
  </div>
</template>

<script>
import request from '@/utils/request'
import { baseUrl } from '@/utils/config'
let msgTimer = null
const regPhone = /^1[3-9]\d{9}$/
export default {
  data() {
    return {
      token: this.$store.state.token,
      userInfo: JSON.parse(JSON.stringify(this.$store.state.userInfo)),
      verification_code: '',
      verification_key: '',
      time: 0
    }
  },
  methods: {
    async sendMsg() {
      try {
        if (this.time) {
          return
        }
        if (!this.userInfo.phone) {
          this.$message.info('请输入手机号')
          return
        }
        if (!regPhone.test(this.userInfo.phone)) {
          this.$message.info('手机号不合法')
          return
        }

        this.time = 60
        const res = await request({
          url: `${baseUrl}/api/sendSms`,
          method: 'post',
          headers: {
            'X-Requested-with': 'XMLHttpRequest',
          },
          data: {
            phone: this.userInfo.phone,
          },
        })
        if (res.code === 200) {
          console.log(res)
          this.verification_key = res.data.key
          msgTimer = setInterval(() => {
            this.time--
            if (this.time <= 0) {
              clearInterval(msgTimer)
              msgTimer = null
            }
          }, 1000)
        }
      } catch (e) {
        console.log(e)
      }
    },
    async chagePhone() {
      try {
        if (!this.userInfo.phone) {
          this.$message.info('请输入手机号')
          return
        }
        if (!this.verification_code) {
          this.$message.info('请输入验证码')
          return
        }
        const res = await request({
          url: `${baseUrl}/api/change_phone`,
          method: 'post',
          headers: {
            Authorization: `Bearer ${this.token}`,
            'X-Requested-with': 'XMLHttpRequest',
          },
          data: {
            phone: this.userInfo.phone,
            verification_key: this.verification_key,
            verification_code: this.verification_code,
          },
        })
        if (res.code === 200) {
          this.getUserInfo()
          this.$message.success('修改成功')
          this.goBack()
        }
      } catch (e) {
        console.log(e)
      }
    },
    async getUserInfo() {
      try {
        const res = await request({
          url: `${baseUrl}/api/user_info`,
          method: 'get',
          headers: {
            Authorization: `Bearer ${this.token}`,
            'X-Requested-with': 'XMLHttpRequest',
          },
        })
        await this.$store.dispatch('getLogin', {
          access_token: this.token,
          user: res.data,
        })
        this.userInfo = res.data
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.phone {
  height: 100%;
  background: rgba(245, 245, 245, 0.9);
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  flex-direction: column;

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    height: 116px;
    font-size: 32px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    position: relative;
    div {
      position: absolute;
      top: 0;
      padding-top: 10px;
      left: 60px;
      height: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
      img {
        margin-right: 12px;
        width: 12px;
        height: 23px;
      }
      p {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.25);
        line-height: 25px;
      }
    }
  }

  .content {
    margin: 45px auto;
    width: 370px;
    .input {
      display: flex;
      flex-direction: column;
      span {
        padding: 0 16px;
        margin-bottom: 11px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
      }
      input {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        line-height: 20px;
        width: 100%;
        height: 44px;
        background: #ffffff;
        box-shadow: 0px 6px 7px -8px rgba(0, 0, 0, 0.3);
        border: 1px solid #259dff;
        border-radius: 12px;
        min-height: 100%;
        outline: none;
        padding: 0 16px;
        &::-webkit-input-placeholder {
          /* WebKit, Blink, Edge */
          color: #e0d8d8;
        }
        &:-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: #e0d8d8;
        }
        &::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: #e0d8d8;
        }
        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: #e0d8d8;
        }
      }
    }

    .input2 {
      margin-top: 24px;
      flex-direction: row;
      input {
        flex: 1;
        overflow: hidden;
      }
      div {
        margin-left: 16px;
        width: 101px;
        height: 44px;
        background: #259dff;
        border-radius: 12px;
        border: 1px solid rgba(255, 255, 255, 0.4);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        cursor: pointer;
      }
    }

    .save {
      margin-top: 60px;
      width: 100%;
      height: 44px;
      background: #259dff;
      border-radius: 12px;
      border: 1px solid rgba(255, 255, 255, 0.4);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 22px;
      cursor: pointer;
    }
  }
}
</style>
