import axios from 'axios'
import { baseUrl } from '@/utils/config'
import store from '../store'
import { Message } from 'element-ui'

// let loading = null;
const service = axios.create({
  timeout: 1000 * 60 * 5
})

service.interceptors.request.use(
  config => {
    // loading = Loading.service({
    //   lock: true,
    //   text: 'Loading',
    //   spinner: 'el-icon-loading',
    //   background: 'rgba(0, 0, 0, 0.7)'
    // });
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    const res = response.data
    console.log(response, res);
    // loading.close();
    // loading = null;
    if (response.config.url.indexOf(baseUrl) > -1 && res.code !== 200) {
      Message({
        message: res.message || res.msg || '服务器错误',
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject(res)
    }
    return res
  },
  error => {
    console.log(error.response);
    const res = error.response
    if (res.config.url.indexOf(baseUrl) > -1 && res.status === 401) {
      store.dispatch('logout').then(() => {
        location.reload()
      })
    }
    // loading.close();
    // loading = null;
    if (res.config.url.indexOf(baseUrl) > -1 && res.config.url.indexOf('/api/get_user_by_scene_id') < 0) {
      Message({
        message: error.response.data.message || error.response.data.msg || '服务器错误',
        type: 'error',
        duration: 5 * 1000
      })
    }

    return Promise.reject(error)
  }
)

export default service
