import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const routes = [
  {
    path: '*',
    redirect: '/',
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login'),
    meta: { title: '登录' }
  },
  {
    path: '/bind',
    name: 'Bind',
    component: () => import('@/views/bind'),
    meta: { title: '绑定微信' }
  },
  {
    path: '/',
    name: 'Home',
    redirect: '/msg',
    component: () => import('@/views/index'),
    meta: { title: '首页' },
    children: [
      {
        path: 'msg',
        name: 'Msg',
        component: () => import('@/views/msg'),
        meta: { title: '首页' }
      },
      {
        path: 'user',
        name: 'User',
        component: () => import('@/views/user'),
        meta: { title: '个人信息' },
        children: [
          {
            path: 'nickname',
            name: 'Nickname',
            component: () => import('@/views/nickname'),
            meta: { title: '修改昵称' },
          },
          {
            path: 'phone',
            name: 'Phone',
            component: () => import('@/views/phone'),
            meta: { title: '绑定手机号' },
          },
          {
            path: 'wechat',
            name: 'Wechat',
            component: () => import('@/views/wechat'),
            meta: { title: '绑定微信号' },
          },
          {
            path: 'changePwd',
            name: 'ChangePwd',
            component: () => import('@/views/changePwd'),
            meta: { title: '修改密码' },
          }
        ]
      },
      {
        path: 'forgetPwd',
        name: 'ForgetPwd',
        component: () => import('@/views/changePwd'),
        meta: { title: '找回密码' },
      },
      {
        path: 'privacy',
        name: 'Privacy',
        component: () => import('@/views/privacy'),
        meta: { title: '隐私策略' },
      },
      {
        path: 'agreement',
        name: 'Agreement',
        component: () => import('@/views/agreement'),
        meta: { title: '用户服务协议' },
      }
    ]
  },

]

const router = new Router({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: () => ({ y: 0 })
})

export default router

