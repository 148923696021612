<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
}
</script>

<style>
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}

input {
  border: 1px solid #ffffff !important;
  transition: border 0.3s;
}

input:focus {
  border: 1px solid #259dff !important;
}

.blue{
  background: #259dff !important;
}
</style>
