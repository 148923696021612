<template>
  <div class="msg">
    <h1 class="msg-title">千一</h1>
    <div class="chat" id="chat">
      <template v-for="(item, index) of list">
        <div class="left" :key="index" v-if="item.type === 'ai'">
          <p v-if="item.showTime" class="begin-time">{{ item.showTime }}</p>
          <template v-if="!item.loading">
            <div @click="playVideo(item.url)" style="cursor: pointer">
              {{ item.content }}
              <div class="can-sound" v-if="item.url">
                <img src="../imgs/can-sound.png" alt="" />
              </div>
              <img
                v-else
                class="can-sound-loading"
                src="../imgs/loading-02.gif"
                alt=""
              />
            </div>
          </template>
          <template v-else>
            <div class="loading">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </template>
        </div>

        <div class="right" :key="index" v-if="item.type === 'user'">
          <p v-if="item.showTime" class="begin-time">{{ item.showTime }}</p>
          <template v-if="!item.loading">
            <div>{{ item.content }}</div>
          </template>
          <template v-else>
            <div class="loading">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </template>
        </div>
      </template>
      <div id="down"></div>
    </div>

    <div class="send">
      <div class="sound" @click="openSound = !openSound">
        <img
          :src="require(`../imgs/sound${!openSound ? '' : '-open'}.png`)"
          alt=""
        />
      </div>

      <div class="robit-video">
        <img v-if="!videoUrl" :src="poster" mode="aspectFill" />
        <template v-else>
          <video v-show="canplay" :src="videoUrl" ref="video" />
          <img v-show="!canplay" :src="poster" mode="aspectFill" />
        </template>
      </div>

      <div class="input-body">
        <div class="input">
          <input
            v-model.trim="value"
            type="text"
            placeholder="请输入内容"
            @keyup.enter="sendMsg"
          />
          <img src="../imgs/send.png" @click="sendMsg" />
        </div>
      </div>
    </div>

    <div
      class="down"
      :style="{
        opacity: showDown ? 1 : 0,
      }"
      @click="getDown"
    >
      <img src="../imgs/down.png" alt="" />
    </div>
  </div>
</template>

<script>
import storage from '../utils/storage'
import request from '@/utils/request'
import moment from 'moment'
import { baseUrl, socketUrl, didConfig, ossUrl } from '@/utils/config'
// const {  websocketUrl, authUrl, accessKey, secretKey } = aiTalk
const { password, url: didUrl, voice_id } = didConfig
import { formatChatTime } from '@/utils/util'
let timer = null

let msgTimer = null

let resultTimer = null

let scrollTime = null

export default {
  data() {
    return {
      comment_id: '',
      poster:
        'https://1001chat.oss-cn-hangzhou.aliyuncs.com/img/qianyi006.jpeg',
      user: this.$store.state.userInfo,
      token: this.$store.state.token,
      value: '',
      ws: null, // 聊天器
      list: [],
      openSound: true,
      videoUrl: '',
      showDown: false,
      canplay: false,
      offsetTop: 0,
    }
  },
  watch: {
    openSound(val) {
      storage.set('closeSound', !val)
    },
  },
  mounted() {
    this.openSound = !storage.get('closeSound')
    this.aiAuth()
    this.getHistory(false, true)
    document
      .getElementById('chat')
      .addEventListener('scroll', this.scrollHandle)
  },
  beforeDestroy() {
    clearInterval(timer)
    timer = null
    clearTimeout(resultTimer)
    resultTimer = null
    clearTimeout(msgTimer)
    msgTimer = null
  },
  methods: {
    transferMsgListTime(list) {
      let arr = []
      const today = formatChatTime(new Date().getTime())

      const data = list
      if (!data.length) {
        return []
      }
      if (data.length === 1) {
        const prevTime = new Date(data[0].updated_at).getTime()
        const time = formatChatTime(prevTime)
        const isToday =
          today.year === time.year &&
          today.month === time.month &&
          today.day === time.day
        data[0].showTime = isToday
          ? `${time.hour}:${time.minute}`
          : `${time.year}-${time.month}-${time.day} ${time.hour}:${time.minute}`
        return data
      }

      let last = data[data.length - 1]
      let lastTime = new Date(last.updated_at).getTime()
      let obj = last
      let seeTime = lastTime

      const getList = (i) => {
        if (data.length - i > -1) {
          const prev = data[data.length - i]
          const prevTime = new Date(prev.updated_at).getTime()
          if (lastTime - prevTime < 5 * 1000 * 60) {
            obj = prev
            seeTime = prevTime
            if (data.length - i === 0) {
              const time = formatChatTime(prevTime)
              const isToday =
                today.year === time.year &&
                today.month === time.month &&
                today.day === time.day
              prev.showTime = isToday
                ? `${time.hour}:${time.minute}`
                : `${time.year}-${time.month}-${time.day} ${time.hour}:${time.minute}`
            }
            getList(i + 1)
          } else {
            if (data.length - i === 0) {
              const time = formatChatTime(prevTime)
              const isToday =
                today.year === time.year &&
                today.month === time.month &&
                today.day === time.day
              prev.showTime = isToday
                ? `${time.hour}:${time.minute}`
                : `${time.year}-${time.month}-${time.day} ${time.hour}:${time.minute}`
            } else {
              const time = formatChatTime(seeTime)
              const isToday =
                today.year === time.year &&
                today.month === time.month &&
                today.day === time.day
              obj.showTime = isToday
                ? `${time.hour}:${time.minute}`
                : `${time.year}-${time.month}-${time.day} ${time.hour}:${time.minute}`
            }
            last = prev
            lastTime = new Date(last.updated_at).getTime()
            getList(i + 1)
          }
        } else {
          arr = data
        }
      }

      getList(2)
      return arr
    },
    scrollHandle(e) {
      if (scrollTime) {
        return
      }
      scrollTime = setTimeout(() => {
        this.showDown = this.offsetTop - e.srcElement.scrollTop > 100
        clearTimeout(scrollTime)
        scrollTime = null
      }, 500)
    },
    getDown() {
      document.getElementById('chat').scrollTo({
        top: document.getElementById('down').offsetTop,
        behavior: 'smooth',
      })
    },
    playVideo(url) {
      if (!this.openSound) {
        return
      }
      this.canplay = false
      if (url) {
        this.videoUrl = `${ossUrl}${encodeURIComponent(url)}`
        this.$nextTick(() => {
          const video = this.$refs.video
          video.currentTime = 0.01
          video.addEventListener('canplay', () => {
            this.canplay = true
            video.play()
          })
          video.addEventListener('ended', () => {
            this.videoUrl = ''
          })
        })
      }
      // this.videoUrl = url
    },
    async getHistory(last) {
      const res = await request({
        url: `${baseUrl}/api/comment_histories`,
        method: 'get',
        headers: {
          Authorization: `Bearer ${this.token}`,
          'X-Requested-with': 'XMLHttpRequest',
        },
      })

      if (res.code === 200) {
        const list = res.data.map((s, i) => {
          return {
            ...s,
            type: s.from_user_id ? 'user' : 'ai',
            userIdentity: `${s.from_user_id || s.to_user_id}`,
            uid: `${s.from_user_id || s.to_user_id}`,
            content: s.content,
            loading: last && i === res.data.length - 1,
          }
        })
        this.list = this.transferMsgListTime(list)

        console.log(this.list)

        this.$nextTick(() => {
          document.getElementById('chat').scrollTop =
            document.getElementById('down').offsetTop
          this.offsetTop = document.getElementById('chat').scrollTop

          if (last) {
            this.startVideo(res.data[res.data.length - 1].content)
          }

          // if (enter) {
          //   document.getElementById('chat').scrollTop = 0
          //   // document.getElementById('chat').scrollTo({
          //   //   top: 0,
          //   //   behavior: 'smooth',
          //   // })
          //   this.showDown = true
          // }
        })
      }
    },
    async aiAuth() {
      // const res = await request({
      //   url: authUrl,
      //   method: 'post',
      //   headers: {
      //     'Content-Type': 'application/json;charset=utf-8',
      //   },
      //   data: {
      //     secretKey: secretKey,
      //     accessKey: accessKey,
      //   },
      //   list: [],
      // })

      // console.log('res', res)
      // if (res.code === 0) {
      this.initAi()
      // }
    },
    async getPreHistory(comment_id) {
      await request({
        url: `${baseUrl}/api/comment_update`,
        method: 'post',
        data: {
          comment_id,
        },
        headers: {
          Authorization: `Bearer ${this.token}`,
          'X-Requested-with': 'XMLHttpRequest',
        },
      })
      this.getHistory()
    },
    initAi() {
      const that = this
      const ws = new WebSocket(socketUrl)
      this.ws = ws
      ws.onopen = function () {
        console.log('服务器连接成功')
        that.heartbeatAi()
        timer = setInterval(() => {
          that.heartbeatAi()
        }, 20000)
      }
      ws.onclose = function (e) {
        console.log('服务器连接关闭', e)
        that.initAi()
      }
      ws.onerror = function () {
        console.log('服务器连接出错')
        that.initAi()
      }
      ws.onmessage = function (e) {
        //接收服务器返回的数据
        const result = JSON.parse(e.data)
        if (result.comment_id) {
          that.comment_id = result.comment_id
          that.getPreHistory(result.comment_id)
        }else{
          that.getPreHistory(that.comment_id)
        }
        // if (result.content) {
        //   that.list.push({
        //     type: 'ai',
        //     loading: true,
        //     content: result.content,
        //   })

        //   if (that.openSound) {
        //     that.startVideo(result.content)
        //   }

        //   that.$nextTick(() => {
        //     document.getElementById('chat').scrollTop =
        //       document.getElementById('down').offsetTop
        //   })
        // }
      }
    },

    async startVideo(input) {
      try {
        const res = await request({
          url: didUrl,
          method: 'post',
          data: {
            // source_url: aiModel,
            script: {
              type: 'text',
              input,
              provider: {
                type: 'microsoft',
                voice_id: voice_id,
              },
            },
            config: {
              stitch: true,
            },
          },
          headers: {
            Authorization: `Basic ${password}`,
          },
        })
        this.getVideo(res.id, input)
      } catch (e) {
        const messageList = JSON.parse(JSON.stringify(this.list))
        const index = messageList.findIndex((s) => s.type === 'ai' && s.loading)

        // await request({
        //   url: `${baseUrl}/api/comment_store`,
        //   method: 'post',
        //   data: {
        //     to_user_id: this.user.id,
        //     url: '',
        //     content: input,
        //     type: 3,
        //   },
        //   headers: {
        //     Authorization: `Bearer ${this.token}`,
        //     'X-Requested-with': 'XMLHttpRequest',
        //   },
        // })

        if (index > -1) {
          this.list[index].loading = false

          this.$nextTick(() => {
            document.getElementById('chat').scrollTop =
              document.getElementById('down').offsetTop
          })
        }
      }
    },

    async getVideo(id, content) {
      try {
        const result = await request({
          url: `${didUrl}/${id}`,
          method: 'get',
          params: {
            provider: {
              type: 'amazon',
              voice_id: 'cmn-CN',
            },
          },
          headers: {
            Authorization: `Basic ${password}`,
          },
        })

        console.log('result', result)

        if (result.status !== 'done') {
          resultTimer = setTimeout(() => {
            this.getVideo(id, content)
          }, 500)
        } else {
          // await request({
          //   url: `${baseUrl}/api/comment_store`,
          //   method: 'post',
          //   data: {
          //     to_user_id: this.data.user.id,
          //     url: result.result_url,
          //     content,
          //     type: 3,
          //   },
          //   headers: {
          //     Authorization: `Bearer ${this.data.token}`,
          //     'X-Requested-with': 'XMLHttpRequest',
          //   },
          // })

          const messageList = JSON.parse(JSON.stringify(this.list))
          const index = messageList.findIndex(
            (s) => s.type === 'ai' && s.loading
          )

          if (index > -1) {
            this.list[index].loading = false
            this.list[index].content = content
          }

          this.videoUrl = result.result_url
          this.$nextTick(() => {
            document.getElementById('chat').scrollTop =
              document.getElementById('down').offsetTop
          })
        }
      } catch (e) {
        console.log(12233, e)
        clearTimeout(resultTimer)
        resultTimer = null

        // await request({
        //   url: `${baseUrl}/api/comment_store`,
        //   method: 'post',
        //   data: {
        //     to_user_id: this.data.user.id,
        //     url: '',
        //     content,
        //     type: 3,
        //   },
        //   headers: {
        //     Authorization: `Bearer ${this.data.token}`,
        //     'X-Requested-with': 'XMLHttpRequest',
        //   },
        // })

        const messageList = JSON.parse(JSON.stringify(this.list))
        const index = messageList.findIndex((s) => s.type === 'ai' && s.loading)

        if (index > -1) {
          this.list[index].loading = false
          this.list[index].content = content
        }

        this.$nextTick(() => {
          document.getElementById('chat').scrollTop =
            document.getElementById('down').offsetTop
        })
      }
    },

    heartbeatAi() {
      this.ws.send('PING')
    },

    async sendMsg() {
      if (!this.value) {
        this.$message.info('内容不能为空')
        return
      }

      const value = JSON.parse(JSON.stringify(this.value))

      this.list.push({
        type: 'user',
        userIdentity: `${this.user.id}`,
        uid: `${this.user.id}`,
        content: value,
      })

      await request({
        url: `${baseUrl}/api/comment_store`,
        method: 'post',
        data: {
          from_user_id: this.user.id,
          content: value,
          type: 1,
        },
        headers: {
          Authorization: `Bearer ${this.token}`,
          'X-Requested-with': 'XMLHttpRequest',
        },
      })

      // this.list.push({
      //   type: 'ai',
      //   loading: true,
      // })

      // const time = moment().format('YYYY-MM-DD hh:mm:ss')

      // this.getAiMsg()
      // msgTimer = setInterval(() => {
      //   this.getAiMsg()
      // }, 1000)

      this.ws.send(
        JSON.stringify({
          // method: 'aiChatReq',
          userIdentity: `${this.user.id}`,
          seq: moment().format('YYYY-MM-DD HH:mm:ss'),
          msg: this.value,
          // uid: `${this.user.openid}`,
          // content: this.value,
          // robotId: robotId,
          // seq: Date.now(),
        })
      )

      setTimeout(() => {
        this.list.push({
          type: 'ai',
          userIdentity: `${this.user.id}`,
          uid: `${this.user.id}`,
          loading: true,
        })
        this.$nextTick(() => {
          document.getElementById('chat').scrollTop =
            document.getElementById('down').offsetTop
        })
      }, 3000)

      this.$nextTick(() => {
        this.value = ''
        document.getElementById('chat').scrollTop =
          document.getElementById('down').offsetTop
      })
    },

    async getAiMsg() {
      const res = await request({
        url: `${baseUrl}/api/newest_comment`,
        method: 'get',
        headers: {
          Authorization: `Bearer ${this.token}`,
          'X-Requested-with': 'XMLHttpRequest',
        },
      })
      if (res.code === 200 && res.data === 1) {
        clearTimeout(msgTimer)
        msgTimer = null
        this.getHistory(true)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@keyframes loading1 {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(0px, -4px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes loading2 {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(0px, -8px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes loading3 {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(0px, -6px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes loading4 {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(0px, -4px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

.msg {
  width: 100%;
  height: 100%;
  background: rgba(245, 245, 245, 0.9);
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .msg-title {
    height: 80px;
    display: flex;
    align-items: center;
    padding-left: 130px;
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 33px;
  }

  .chat {
    flex: 1;
    overflow: auto;
    padding: 0 130px;

    .begin-time {
      font-size: 11px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.2);
      line-height: 16px;
      text-align: center;
      padding: 16px 0 26px 0;
    }

    .left {
      margin-bottom: 16px;
      width: 100%;
      text-align: left;
      > div {
        padding: 12px 14px;
        max-width: 750px;
        width: auto;
        display: inline-flex;
        background: #ffffff;
        border-radius: 12px;
        // border: 1px solid rgba(0, 0, 0, 0.2);
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: 20px;
        white-space: normal;
        word-break: break-all;
        position: relative;
        .can-sound {
          position: absolute;
          bottom: 3px;
          right: -42px;
          width: 32px;
          height: 32px;
          background: #6cc7ff;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 18px;
          }
        }

        .can-sound-loading {
          position: absolute;
          bottom: 3px;
          right: -42px;
          width: 32px;
          height: 32px;
        }
      }
    }

    .right {
      margin-bottom: 16px;
      width: 100%;
      text-align: right;
      div {
        padding: 6px 14px 7px 14px;
        max-width: 750px;
        width: auto;
        display: inline-block;
        background: #259dff;
        border-radius: 12px;
        // border: 1px solid rgba(255, 255, 255, 0.4);
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 20px;
        text-align: left;
        white-space: normal;
        word-break: break-all;
      }
    }

    .loading {
      text-align: center;
      display: flex;
      align-items: center;
    }

    .loading div {
      width: 4px;
      height: 4px;
      background: #ffffff;
      margin: 0 2px;
      padding: 0;
    }

    .left .loading div {
      background: rgba(0, 0, 0, 0.85);
    }

    .loading div:nth-child(1) {
      animation: loading1 1.5s infinite linear;
    }

    .loading div:nth-child(2) {
      animation: loading2 1.5s infinite;
    }

    .loading div:nth-child(3) {
      animation: loading3 1.5s infinite;
    }

    .loading div:nth-child(4) {
      animation: loading4 1.5s infinite;
    }
  }

  .send {
    height: 144px;
    display: flex;
    align-items: center;
    background: #3da1f6;
    box-shadow: 0px 14px 32px -10px rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.5);
    padding: 0 130px 0 36px;
    // justify-content: space-between;
    overflow: hidden;

    .sound {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.2);
      box-shadow: 0px 6px 16px -8px rgba(0, 0, 0, 0.2);
      border: 1px solid rgba(255, 255, 255, 0.7);
      backdrop-filter: blur(5px);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img {
        width: 15px;
        height: 21px;
      }
    }

    .robit-video {
      margin: 0 40px 0 96px;
      width: 144px;
      height: 100%;
      position: relative;
      // width: 115px;
      // mix-blend-mode: lighten;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      video {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
      }
    }

    .input-body {
      flex: 1;
      display: flex;
      justify-content: flex-start;
      .input {
        width: 100%;
        height: 80px;
        background: #ffffff;
        box-shadow: 0px 6px 16px -8px rgba(0, 0, 0, 0.5);
        border-radius: 12px;
        display: flex;
        align-items: center;
        padding: 0 30px;
        overflow: hidden;
        input {
          flex: 1;
          height: 100%;
          min-height: 100%;
          background: none;
          outline: none;
          border: none !important;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.85);
          line-height: 20px;
        }
        img {
          margin-left: 30px;
          width: 30px;
          height: 30px;
          cursor: pointer;
        }
      }
    }
  }
}

.down {
  position: fixed;
  right: 112px;
  bottom: 228px;
  width: 44px;
  height: 44px;
  background: rgba(148, 148, 148, 0.2);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: opacity 0.3s;
  cursor: pointer;
  img {
    width: 18px;
    height: 11px;
  }
}
</style>
