
// 机器人信息
// export const aiTalk = {
//   robotId: 'HQN13Jv1JsZ1S02WttNno',
//   code: 'bmjgseggwv9xznstie',
//   accessKey: '059ba98636064e48955e88b319037ce3',
//   secretKey: '5e5b33ca230d452a99a35333b81a1947',
//   name: '1001Chat',
//   authUrl: 'https://open.galaxyeye-api.com/gateway/v2/saas/authorize', // 鉴权api
//   websocketUrl: 'wss://open.galaxyeye-api.com/ai-ccs-v4/v2/server/aiChat' // websocket API
// }

//baseurl
export const baseUrl = 'https://api.1001chat.com'

export const socketUrl = `wss://api.1001chat.com/wss`
export const ossUrl = 'https://1001chat.oss-cn-hangzhou.aliyuncs.com/'


// did配置
export const didConfig = {
  voice_id: 'zh-CN-XiaoxiaoNeural', // 语音风格
  aiModel: 'https://meiye-1305874752.cos.ap-shanghai.myqcloud.com/test/test00.png', // ai原型
  password: 'MTAwMWNoYXRib3R0ZXN0MDAxQGdtYWlsLmNvbQ:VNIL0odZMA31dFr1Uz4au', // did秘钥token
  url: 'https://api.d-id.com/talks' //构造url
}

// 微信配置
export const wxConfig = {
  appid: 'wx9875eed15e534618',
  redirect_uri: encodeURIComponent(`https://1001chat.com/#/bind`)
}