<template>
  <div class="user-body">
    <template v-if="$route.name === 'User'">
      <div class="user">
        <h1 class="user-title">个人中心</h1>

        <div class="content">
          <div class="box box1">
            <div class="cell img">
              <img
                :src="userInfo.avatar ? userInfo.avatar : poster"
                class="avatar"
              />
              <el-upload
                class="upload-demo"
                :action="`${baseUrl}/api/upload_img`"
                :headers="{
                  Authorization: `Bearer ${this.token}`,
                  'X-Requested-with': 'XMLHttpRequest',
                }"
                name="img"
                accept="image/*"
                :before-upload="beforeUpload"
                :show-file-list="false"
                :on-success="uploadSuccess"
              >
                <div class="edit-avatar">修改头像</div>
              </el-upload>
              <span class="tip">像素大于150*150，大小不超过5MB</span>
            </div>

            <div class="cell">
              <span class="cell-name">昵称</span>
              <div
                class="cell-body"
                @click="$router.push({ name: 'Nickname' })"
              >
                <span>{{ userInfo.nickname }}</span>
                <img src="../imgs/next.png" alt="" />
              </div>
            </div>
          </div>

          <div class="box">
            <div class="cell">
              <span class="cell-name">手机号</span>
              <div class="cell-body" @click="changePhone">
                <span v-if="userInfo.phone"
                  >{{ userInfo.phone.substr(0, 3) }}
                  {{ userInfo.phone.substr(3, 4) }}
                  {{ userInfo.phone.substr(7, 4) }}</span
                >
                <span v-else style="color: #d5cfcf">未绑定</span>
                <img src="../imgs/next.png" alt="" />
              </div>
            </div>

            <div class="cell">
              <span class="cell-name">微信号</span>
              <div class="cell-body" @click="changeWx">
                <span v-if="userInfo.openid">更换绑定</span>
                <span v-else style="color: #d5cfcf">未绑定</span>
                <img src="../imgs/next.png" alt="" />
              </div>
            </div>

            <div class="cell">
              <span class="cell-name">修改密码</span>
              <div class="cell-body" @click="goToChangePwd">
                <img src="../imgs/next.png" alt="" />
              </div>
            </div>
          </div>

          <div class="btns">
            <div @click="logout">退出登录</div>
          </div>
        </div>
      </div>
    </template>
    <template>
      <router-view></router-view>
    </template>
  </div>
</template>

<script>
import { didConfig, baseUrl, ossUrl } from '@/utils/config'
// import { getFileByUserSelect } from '@/utils/file'
// const {  websocketUrl, authUrl, accessKey, secretKey } = aiTalk
const { aiModel } = didConfig
import request from '@/utils/request'
export default {
  data() {
    return {
      ossUrl,
      baseUrl,
      token: this.$store.state.token,
      poster: aiModel,
      userInfo: this.$store.state.userInfo,
      validWidth: 150,
      validHeight: 150,
    }
  },
  watch: {
    '$route.name'() {
      this.getUserInfo()
    },
  },
  created() {
    this.getUserInfo()
  },
  methods: {
    goToChangePwd() {
      if (!this.userInfo.phone) {
        this.$message.info('请先绑定手机号')
        return
      }
      this.$router.push({ name: 'ChangePwd' })
    },
    valWidthAndHeight(file) {
      let _this = this
      return new Promise(function (resolve, reject) {
        let width = _this.validWidth // 图片宽度
        let height = _this.validHeight // 图片高度
        let _URL = window.URL || window.webkitURL
        let image = new Image()
        image.src = _URL.createObjectURL(file)
        image.onload = function () {
          let valid = image.width >= width && image.height >= height
          valid ? resolve() : reject()
        }
      }).then(
        () => {
          return file
        },
        () => {
          this.$message.info(
            '头像尺寸需要大于' + _this.validWidth + '*' + _this.validHeight
          )
          return Promise.reject()
        }
      )
    },
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 <= 5
      if (!isLt2M) {
        this.$message.info('头像大小不能超过5MB')
        return false
      }
      // 限制图片的大小
      if (this.validWidth && this.validHeight) {
        return this.valWidthAndHeight(file)
      } else {
        return isLt2M
      }
    },
    async changeWx() {
      try {
        if(!this.userInfo.openid){
          this.$router.push({ name: 'Wechat' })
          return
        }
        await this.$confirm('您确定更换绑定吗？更换绑定后原微信号将无法登录本账号，只能使用新绑定的微信号进行登录！', '谨慎操作提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          confirmButtonClass: 'blue',
          type: 'warning',
        })
        this.$router.push({ name: 'Wechat' })
      } catch {
        console.log('取消')
      }
    },
    async changePhone() {
      try {
        if(!this.userInfo.phone){
          this.$router.push({ name: 'Phone' })
          return
        }
        await this.$confirm('您确定更换绑定吗？更换绑定后原手机号将无法登录本账号，只能使用新绑定的手机号进行登录！', '谨慎操作提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          confirmButtonClass: 'blue',
          type: 'warning',
        })
        this.$router.push({ name: 'Phone' })
      } catch {
        console.log('取消')
      }
    },
    async getUserInfo() {
      try {
        const res = await request({
          url: `${baseUrl}/api/user_info`,
          method: 'get',
          headers: {
            Authorization: `Bearer ${this.token}`,
            'X-Requested-with': 'XMLHttpRequest',
          },
        })
        await this.$store.dispatch('getLogin', {
          access_token: this.token,
          user: res.data,
        })
        this.userInfo = res.data
      } catch (e) {
        console.log(e)
      }
    },
    async logout() {
      await this.$store.dispatch('logout')
      this.$router.replace('/login')
    },
    uploadSuccess(e) {
      this.chageUserInfo({
        avatar: e.data,
      })
    },
    changeData() {
      this.chageUserInfo({
        nickname: this.userInfo.nickname,
      })
    },
    async chageUserInfo(data = {}) {
      try {
        const postData = data
        const res = await request({
          url: `${baseUrl}/api/change_user_info`,
          method: 'post',
          headers: {
            Authorization: `Bearer ${this.token}`,
            'X-Requested-with': 'XMLHttpRequest',
          },
          data: postData,
        })
        if (res.code === 200) {
          this.getUserInfo()
          this.$message.success('修改成功')
        }
      } catch (e) {
        console.log(e)
      }
    },
    // async changeAvatar() {
    //   const res = await getFileByUserSelect(false)
    //   console.log(res)
    //   // input.remove()
    // },
  },
}
</script>

<style lang="scss" scoped>
.user-body {
  height: 100%;
}
.user {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #f5f5f5;
  border-radius: 16px;

  .user-title {
    margin: 25px 0 0 130px;
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 33px;
  }

  .content {
    flex: 1;
    margin: 39px auto 72px auto;
    width: 640px;
    display: flex;
    flex-direction: column;
    overflow: auto;

    .box {
      background: #ffffff;
      box-shadow: 0px 6px 16px -8px rgba(0, 0, 0, 0.5);
      border-radius: 12px;
      .cell {
        padding: 0 40px;
        height: 58px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        &:last-child {
          border: none;
        }
        .cell-name {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.85);
          line-height: 22px;
        }
        .cell-body {
          flex: 1;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          cursor: pointer;
          span {
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #259dff;
            line-height: 22px;
          }
          img {
            margin-left: 12px;
            width: 8px;
            height: 15px;
          }
        }
      }

      .img {
        padding: 23px 40px;
        height: auto;
        position: relative;
        .avatar {
          width: 84px;
          height: 84px;
          box-shadow: 0px 12px 16px -10px rgba(0, 0, 0, 0.3);
          border: 1px solid rgba(255, 255, 255, 0.7);
          border-radius: 50%;
          object-fit: cover;
        }
        .edit-avatar {
          width: 91px;
          height: 36px;
          border-radius: 8px;
          border: 1px solid #259dff;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #259dff;
          line-height: 20px;
        }

        .tip {
          position: absolute;
          right: 42px;
          bottom: 24px;
          font-size: 13px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.25);
          line-height: 18px;
        }
      }
    }

    .box1 {
      margin-bottom: 20px;
    }

    .btns {
      margin-top: 130px;
      display: flex;
      align-items: center;
      justify-content: center;
      div {
        width: 370px;
        height: 44px;
        background: #259dff;
        border-radius: 8px;
        border: 0px solid rgba(255, 255, 255, 0.4);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }
}
</style>
