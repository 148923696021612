<template>
  <div></div>
</template>

<script>
import { baseUrl } from '@/utils/config'
import request from '@/utils/request'
export default {
  created() {
    const { code, state } = this.GetRequest() 
    this.bindUser(code, state.split('#')[0])
  },
  methods: {
    async bindUser(code, user_id) {
      try {
        await request({
          url: `${baseUrl}/api/change_wx`,
          method: 'post',
          headers: {
            'X-Requested-with': 'XMLHttpRequest',
          },
          data: {
            code,
            user_id,
          },
        })
        this.$message.success('绑定成功,请退出当前页面')

        setTimeout(() => {
          window.WeixinJSBridge.invoke('closeWindow')
        }, 1500);
      } catch (e) {
        console.log(e)
      }
    },
    // 获取参数
    GetRequest() {
      const url = window.location.href // 获取url中"?"符后的字串
      const urls = url.substring(url.indexOf('?'), url.length)
      var theRequest = {}
      if (urls.indexOf('?') !== -1) {
        const str = urls.substr(1)
        const strs = str.split('&')
        for (let i = 0; i < strs.length; i++) {
          theRequest[strs[i].split('=')[0]] = decodeURIComponent(
            strs[i].split('=')[1]
          )
        }
      }
      return theRequest
    },
  },
}
</script>

<style></style>
