<template>
  <div class="nickname">
    <h1 class="title">{{ $route.meta.title }}</h1>
    <div class="content">
      <h1>一、服务概述</h1>
      <p>
        《1001CHAT用户服务协议》是武汉钱意科技有限公司(以下简称“钱意科技”)与您就钱意科技所提供1001CHAT平台（以下简称“本平台”）的产品及服务的相关事项所订立的有效合约。1001CHAT平台的产品及服务由钱意科技及其子公司提供。
      </p>
      <p>
        1.1.
        钱意科技目前经由本平台网站，向您提供服务。本服务条款适用于钱意科技提供的各种服务，但当您使用钱意科技某一特定服务时，如该服务另有单独的服务条款、指引或规则，您应遵守本服务条款及钱意科技随时公布的与该服务相关的服务条款、指引或规则等。前述所有的指引和规则，均构成本服务条款的一部分。除非本服务条款另有其他明示规定，本平台新推出的产品或服务、增加或强化目前本服务的任何新功能，均受到本服务条款之规范。
      </p>
      <p>
        <strong
          >1.2.
          重要须知：钱意科技在此特别提醒，您欲访问本平台或使用本平台所提供的服务，必须事先认真阅读本服务协议中各条款，包括免除钱意科技责任的条款及对您权利进行限制的条款，前述条款将以加粗或加下划线的形式提示；如对相应条款有疑问，请通过邮箱【1001chat@xr1001.com】进行询问，钱意科技将向您进行解释和说明；如您无法准确理解钱意科技对条款的解释和说明，或者您不同意本协议的任一内容，请不要进行后续操作。您通过网络页面点击确认、实际购买或使用钱意科技所提供的服务等操作均表示您已阅读并充分理解本协议之内容，并同意接受本协议的全部约定内容，受其约束。
        </strong>
      </p>
      <h1>二、1001CHAT帐号服务简介</h1>
      <p>
        <strong
          >2.1.
          本服务条款所称的“1001CHAT帐号”是指您注册的合法有效帐号，包括以手机号码或者钱意科技认可的其他方式注册的帐号。
        </strong>
      </p>
      <p>
        <strong
          >2.2.
          您理解并确认，1001CHAT帐号是钱意科技按照本服务条款授权您使用1001CHAT服务的数字标识。在法律法规允许的范围内，1001CHAT帐号的所有权，以及与注册、使用1001CHAT帐号相关的服务数据和记录，包括但不限于所有注册、登陆、消费记录、产品日志、客服记录和相关的使用统计数据等，归钱意科技所有。发生争议时，您同意以钱意科技的系统数据为准，钱意科技保证该数据的真实性。
        </strong>
      </p>
      <p>
        2.3.
        您完成1001CHAT帐号申请注册程序后，钱意科技按照本服务条款授权您使用该帐号，或者以钱意科技明确许可的其他目的，依照钱意科技的服务规则使用该帐号。钱意科技运用自己的操作系统通过国际互联网络为您提供各项服务。您必须：
      </p>
      <p>（1）提供设备，如个人电脑、手机或其他上网设备。</p>
      <p>（2）个人上网和支付与此服务有关的费用。</p>
      <h1>三、账户的注册和使用规则</h1>
      <p>
        3.1.
        您确认，在注册或者使用1001CHAT服务时，应当具备中华人民共和国法律规定的与其行为相适应的民事行为能力，确保有能力对所使用1001CHAT服务的一切行为独立承担责任。<strong
          >若您系未满十八周岁的未成年人，请在监护人的陪同下阅读本服务条款，并在取得监护人对本服务条款及对使用1001CHAT服务的行为的同意之后，方可注册并使用1001CHAT服务；钱意
          科技在依据法律规定或本服务条款要求您承担责任时，有权向您的监护人追偿。
        </strong>
      </p>
      <p>
        3.2.
        1001CHAT帐号注册资料包括但不限于：您的手机号码、密码、注册或更新1001CHAT帐号时输入的所有信息以及您使用1001CHAT各单项服务时输入的名称、头像等所有信息。
      </p>
      <p>
        3.3.
        用户在本产品注册时，不得使用虚假身份信息。用户应当妥善保存其账户信息和密码，由于用户泄密所导致的损失需由用户自行承担。如用户发现他人冒用或盗用其账户或密码，或其账户存在其他未经合法授权使用之情形，应立即以有效方式通知我们。用户理解并同意我们有权根据用户的通知、请求或依据判断，采取相应的行动或措施，包括但不限于冻结账户、限制账户功能等，我们对采取上述行动所导致的损失不承担除法律有明确规定外的责任。
      </p>
      <p>
        您在注册1001CHAT帐号时承诺遵守法律法规、社会主义制度、国家利益、公民合法权益、公共秩序、社会道德风尚和信息真实性等底线和准则，不得在1001CHAT帐号注册资料中出现违法和不良信息，且您保证其在注册和使用帐号时，不得有以下情形：
      </p>
      <p>(1) 违反相关法律法规，包括：</p>
      <ul>
        <li>
          <p>违反宪法或法律法规规定的；</p>
        </li>
        <li>
          <p>
            危害国家安全，泄露国家秘密，颠覆国家政权，推翻社会主义制度，煽动分裂国家，破坏国家统一的；
          </p>
        </li>
        <li>
          <p>损害国家荣誉和利益的，损害公共利益的；</p>
        </li>
        <li>
          <p>
            歪曲、丑化、亵渎、否定英雄烈士事迹和精神，以侮辱、诽谤或者其他方式侵害英雄烈士的姓名、肖像、名誉、荣誉的；
          </p>
        </li>
        <li>
          <p>宣扬恐怖主义、极端主义或者煽动实施恐怖活动、极端主义活动的；</p>
        </li>
        <li>
          <p>煽动民族仇恨、民族歧视，破坏民族团结的；</p>
        </li>
        <li>
          <p>破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
        </li>
        <li>
          <p>散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
        </li>
        <li>
          <p>
            散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的，传授犯罪手段、方法，制造或者交易违禁物品、管制物品，实施诈骗以及其他违法犯罪活动的；
          </p>
        </li>
        <li>
          <p>侮辱或者诽谤他人，侵害他人合法权益的；</p>
        </li>
        <li>
          <p>
            仿冒、假借国家机构、社会团体及其工作人员或者其他法人名义散布的，或者为实施违法犯罪而冒用他人名义的；
          </p>
        </li>
        <li>
          <p>含有法律、行政法规禁止的其他内容的。</p>
        </li>
      </ul>
      <p>(2) 不友善对话，包括：</p>
      <ul>
        <li>
          <p>人身攻击及辱骂他人。</p>
        </li>
        <li>
          <p>
            针对以下群体发表诅咒、歧视、漠视生命尊严等性质的言论，群体包括：国籍、地域、性别、性别认同、性倾向、种族、疾病、宗教、残障群体等。
          </p>
        </li>
        <li>
          <p>对他人进行诅咒、恐吓或威胁。</p>
        </li>
        <li>
          <p>针对其他用户的私德、观点立场、素质、能力等方面的贬低或不尊重。</p>
        </li>
        <li>
          <p>讽刺其他用户，阴阳怪气地表达批评。</p>
        </li>
        <li>
          <p>对其他用户创作的内容直接进行贬低性的评论。</p>
        </li>
        <li>
          <p>* 对其他用户使用粗俗用语，并产生了冒犯。</p>
        </li>
        <li>
          <p>
            针对以下群体发表偏见性质的言论，群体包括：国籍、地域、性别、性别认同、性倾向、种族、疾病、宗教、残疾人群体等。
          </p>
        </li>
      </ul>
      <p>(3) 恶意言论，包括：</p>
      <ul>
        <li>
          <p>发布难以辨识涵义影响阅读体验的字符、数字等无意义乱码。</p>
        </li>
        <li>
          <p>
            恶意对抗行为，包括但不限于使用变体、谐音等方式规避产品或服务检测来上传违反上述（1）和（2）的言论。
          </p>
        </li>
      </ul>
      <p>
        (4)
        其他违反法律法规规定、侵犯其他用户合法权益、干扰产品或服务正常运营或1001CHAT未明示授权的行为。
      </p>
      <p>
        3.4.
        未经1001CHAT书面许可，任何人均不得自行或授权、允许、协助他人对1001CHAT软件及相关服务的信息内容（包括但不限于图片、文字、电子信息等）进行任何形式的复制、收集、编辑、开发等用途，或者干扰1001CHAT的正常经营行为。
      </p>
      <h1>四、服务的变更、暂停与终止</h1>
      <p>
        <strong
          >4.1.
          为不断完善使用体验、改进服务水平及质量，您充分理解并同意钱意科技有权变更相关服务，钱意科技在变更相关服务前尽可能通过站内信、平台公告等方式通知您；如您对变更事项不同意的，您应当于变更事项确定的生效之日起停止使用本平台以及钱意科技的其他产品或服务，如您在变更事项生效后仍继续使用本平台以及钱意科技的其他产品或服务，则视为您同意已生效的变更事项。
        </strong>
      </p>
      <p>
        <strong>
          4.2.
          您充分理解并同意钱意科技有权因服务器或系统的维护、升级需要或其他原因，暂停平台服务的全部或者部分内容；对暂停事项，钱意科技将尽可能事先公告。
        </strong>
      </p>
      <p>
        <strong
          >4.3.
          您理解并同意当您严重违反相关法律法规规定或本协议项下之任一承诺内容等情形时，钱意科技有权终止服务；钱意科技将在平台服务终止前提前在相关平台或网站上公告，且无需单独通知您，除法律法规明确要求钱意科技承担责任之外，钱意科技无需因平台服务的任何变更、暂停和终止向您或者任何第三方承担任何责任。
        </strong>
      </p>
      <h1>五、 信息安全与保密义务</h1>
      <p>
        <strong>
          5.1.
          保护您的信息安全是钱意科技的一项基本政策。钱意科技会按照公布的《钱意科技个人信息保护政策》保护您的相关信息。钱意科技不会公开或透露您的注册资料及采集、存储、上传到1001CHAT服务中的非公开信息，除非钱意科技在诚信的基础上认为透露这些信息在以下几种情况是必要的：
        </strong>
      </p>
      <p><strong>（1）事先获得您的授权；</strong></p>
      <p>
        <strong
          >（2）在国家有关机关依法查询或调阅时，遵守有关法律规定或政策文件要求，向有关机关或第三方提供您的注册资料、您在1001CHAT服务器或者网页上发布、存储、传播或钱意科技在1001CHAT服务中采集的信息内容及其发布时间、互联网地址或者域名等；
        </strong>
      </p>
      <p>
        <strong
          >（3）钱意科技可能会与第三方合作向您提供1001CHAT的相关服务，在此情况下，如该第三方同意承担与钱意科技同等的保护您信息安全的责任，则钱意科技可将您的信息提供给该第三方；
        </strong>
      </p>
      <p><strong>（4）保持维护钱意科技的知识产权和其他重要权利；</strong></p>
      <p><strong>（5）在紧急情况下竭力维护您和社会大众的信息安全；</strong></p>
      <p>
        <strong
          >（6）根据本服务条款相关规定或者钱意科技认为必要的其他情况。</strong
        >
      </p>
      <p>
        5.2.
        您可自行对您的信息内容进行上传、删除、更改等操作。钱意科技建议您应谨慎进行前述操作，在删除信息之前进行备份。
      </p>
      <p>
        5.3.
        您对于因使用1001CHAT服务而了解或接触到的钱意科技的商业秘密及其他机密和信息，相关技术、程序、资料、文档，协议内容、价款及及往来文件（以下统称“保密信息”）均应保守秘密；非经钱意科技书面同意，不得向第三方泄露、给予或转让该保密信息。保密协议不因本服务条款的终止而终止。
      </p>
      <h1>六、法律责任</h1>
      <p>
        <strong
          >6.1.
          如果我们发现或收到他人举报或投诉用户违反本协议约定的，1001CHAT有权不经通知随时对相关内容，包括但不限于用户资料、聊天记录进行审查、删除，并视情节轻重对违规帐号处以包括但不限于警告、帐号封禁、设备封禁、功能封禁的处罚，且通知用户处理结果。
        </strong>
      </p>
      <p>
        <strong
          >6.2.
          用户理解并同意，1001CHAT有权依合理判断对违反有关法律法规或本协议规定的行为进行处罚，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，1001CHAT不承担由此产生的任何法律责任。针对您违反本协议或其他服务条款的行为，1001CHAT有权独立判断并视情况采取预先警示、拒绝发布、立即停止传输信息、删除内容或评论、限制帐号部分或者全部功能直至终止提供服务、永久关闭帐号等措施，对于因此而造成您无法正常使用帐号及相关服务、无法正常获取您帐号内资产或其他权益等后果，1001CHAT不承担任何责任。1001CHAT有权公告处理结果，且有权根据实际情况决定是否恢复相关帐号的使用。对涉嫌违反法律法规、涉嫌违法犯罪的行为，1001CHAT将保存有关记录，并有权依法向有关主管部门报告、配合有关主管部门调查、向公安机关报案等。对已删除内容1001CHAT有权不予恢复。
        </strong>
      </p>
      <p>
        <strong>
          6.3.
          用户理解并同意，因用户违反相关法律法规或本协议约定引发的任何后果，均由用户独立承担责任、赔偿损失，与1001CHAT无关。如侵害到1001CHAT或他人权益的，用户须自行承担全部责任和赔偿一切损失（包括但不限于经济、商誉、维权支出、律师费等损失）。
        </strong>
      </p>
      <h1>七、免责声明</h1>
      <p>
        <strong>
          7.1.
          您理解并同意，1001CHAT为了整体服务运营、平台运营安全的需要，有权视具体情况决定服务/功能的设置及其范围修改、中断、中止或终止1001CHAT软件及相关服务。
        </strong>
      </p>
      <p>
        <strong>
          7.2.
          您理解并同意，1001CHAT通过系统自动生成的虚拟数字人所向您提供的对话以及其他的服务等，系根据现有算法等技术手段生成的，1001CHAT会尽最大的努力为您提供服务。但是，由于技术手段天然的局限性以及软件和服务会受到多方面因素（不可抗力、政府行为、疫情防控、法律法规、技术风险、第三方服务瑕疵等）的影响或干扰，1001CHAT将不断完善与探索优化方案，但是不保证（包括但不限于）：
        </strong>
      </p>
      <p>
        <strong>
          (1)
          进行任何明示或暗示的担保（适用性、所有权、不侵权以及其他各种在法律法规允许的范围内的其他可能性）；
        </strong>
      </p>
      <p>
        <strong>
          (2)
          软件及相关服务适合您的使用要求、需要或完全避免给您带来不适或其他有害要素；
        </strong>
      </p>
      <p>
        <strong>
          (3)
          软件及相关服务不受任何干扰，不会中断或发生错误，及时、安全、可靠并满足或符合您的期望，或在您选择的任意时间、地点使用软件及相关服务；
        </strong>
      </p>
      <p><strong>(4) 任何错误、缺陷都能得到修正；</strong></p>
      <p>
        <strong
          >(5)
          软件及相关服务的反馈或话语完全满足您的道德观念、宗教信仰等。</strong
        >
      </p>
      <p>
        <strong>
          1001CHAT再次向您提醒，由于技术的客观性，1001CHAT的软件、服务等可能存在不可避免地冒犯您的话语或相关反馈，1001CHAT并没有主观上侵权的故意。
        </strong>
      </p>
      <p>
        <strong>
          如果您不能理解上述全部的情形并豁免1001CHAT的相关责任，同时对1001CHAT软件及相关服务的使用行为应自行承担相应风险，请您不要注册并使用1001CHAT提供的相关服务。
        </strong>
      </p>
      <p>
        <strong>
          7.3.
          您理解并同意，您在使用1001CHAT软件及相关服务时，1001CHAT向您显示的所有虚拟内容，包括但不限于虚拟数字人及其对话等要素，均主要是基于1001CHAT的算法和自身内容库向您进行的反馈和展示，其所有权均归属于1001CHAT，因此如果您不能理解上述情形并同意1001CHAT无需取得您的同意，随时自行设置、修改相关服务规则，请您不要注册并使用1001CHAT提供的相关服务。
        </strong>
      </p>
      <p>
        <strong>
          7.4.
          在任何情况下，1001CHAT均不对任何间接性、后果性、惩罚性、偶然性、特殊性或刑罚性的损害，包括因您使用1001CHAT软件及相关服务而遭受的利润损失，承担责任。除法律法规另有明确规定外，1001CHAT对您承担的全部责任，无论因何原因或何种行为方式，始终不超过您因使用1001CHAT软件及相关服务期间而支付给1001CHAT的费用（如有）。
        </strong>
      </p>
      <p>
        <strong>
          7.5.
          本协议旨在保障遵守国家法律法规、维护公序良俗，保护合法权益，1001CHAT在能力范围内尽最大的努力按照相关法律法规进行判断，但受限于自身的局限性，并不保证1001CHAT判断完全与司法机关、行政机关的判断一致，如因此产生的后果您已经理解并同意自行承担。
        </strong>
      </p>
      <h1>八、知识产权</h1>
      <p>
        8.1.
        钱意科技是本平台服务及产品以及任何其他相关内容的知识产权的完整和全部的合法权利人。您充分理解并同意，您仅拥有依照本协议约定合法使用平台服务的权利，与平台服务相关的一切著作权、商标权、专利权、商业秘密等知识产权均受中华人民共和国法律法规和相应的国际条约保护，钱意科技享有上述知识产权。未经钱意科技或相关权利人书面同意，您不得为任何商业或非商业目的自行或许可任何第三方实施、利用、转让上述知识产权。
      </p>
      <p>
        <strong>
          8.2.
          您充分理解并同意，平台服务的呈现内容中可能包含钱意科技、其关联公司及其合作伙伴的任何版权标识、商标、服务标记、标识、商号、企业名称、域名及其他明显的标志，您不得以任何方式进行删除、掩藏、修改或替换该等信息或者标志。
        </strong>
      </p>
      <p>
        <strong>
          8.3.
          您不得以任何方式删除、掩藏、修改或替换平台服务及呈现内容中所附的或包含的任何专有权利声明（包含但不限于著作权、商标权、专利权、所有权声明等）、其他任何链接或公告等。
        </strong>
      </p>
      <p>
        <strong>
          8.4.
          您充分理解并同意，在此授予钱意科技免费的、永久性的、不可撤销的、非独家的和不可转让的权利和许可，在本协议期限内使用您的任何版权标识、商标、服务标记、标识、商号、企业名称、域名及其他明显的标志来宣传您对平台服务的使用。
        </strong>
      </p>
      <p>
        <strong>
          8.5.
          您承诺提供至钱意科技的各项信息、使用平台服务过程中上传的全部数据、对平台服务的使用，以及使用平台服务所涉及的素材或产生的相应成果不会侵犯任何第三方的合法权益；如有第三方就您所提供、上传或使用的信息、数据或就您的行为及工作成果对钱意科技进行约谈、处罚等或向钱意科技提起任何投诉、索赔、诉讼或其他类型的诉求，则您理解并确认，您应立即出面解决，并应赔偿钱意科技因此遭受的全部经济损失，包括但不限于各种费用、赔偿支出等。
        </strong>
      </p>
      <p>
        8.6.
        如果第三方机构或个人对钱意科技的知识产权归属提出质疑或投诉，您应及时告知钱意科技，钱意科技将及时进行处理。
      </p>
      <h1>九、法律适用及争议解决</h1>
      <p>
        9.1.
        本协议的订立、效力、解释、履行、修改和终止，访问和使用平台服务以及争议的解决均适用中华人民共和国（仅为本协议之目的，不包括香港、澳门和台湾）法律。
      </p>
      <p>
        9.2.
        因访问和使用平台服务而发生的任何争议，各方应首先通过友好协商的方式加以解决。协商不成时，任何一方均可向钱意科技所在地的人民法院提起诉讼。因访问和使用平台服务而发生任何争议或任何争议正在进行诉讼时，除争议的事项外，各方仍应继续行使各自的其他权利并履行各自的其他义务。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
.nickname {
  height: 100%;
  background: rgba(245, 245, 245, 0.9);
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .title {
    display: flex;
    justify-content: center;
    padding: 40px 0 30px;
    font-size: 32px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .content {
    padding: 30px;
    width: 100%;
    flex: 1;
    overflow: auto;
    white-space: normal;
    word-break: break-all;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 20px;
    h1 {
      font-size: 18px;
      line-height: 40px;
    }
    h2 {
      font-size: 16px;
      line-height: 36px;
    }
    p {
      line-height: 30px;
      text-indent: 2em;
    }
    ul {
      li {
        text-indent: 2em;
        list-style-type: disc;
        list-style-position: inside;
        p {
          text-indent: 0;
          display: inline-block;
        }
      }
    }
  }
}
</style>
