import Vue from 'vue'
import {
  Button,
  Input,
  Select,
  Option,
  DatePicker,
  Message,
  MessageBox,
  Icon,
  Loading,
  Backtop,
  Link,
  Tabs,
  TabPane,
  Radio,
  Upload,
} from 'element-ui'

Vue.use(Button)
Vue.use(Input)
Vue.use(Select)
Vue.use(Option)
Vue.use(DatePicker)
Vue.use(Icon)
Vue.use(Backtop)
Vue.use(Link)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Radio)
Vue.use(Upload)



Vue.use(Loading.directive);
Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;





