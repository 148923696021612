export const formatChatTime = timeString => {
  if (timeString.toString().indexOf('-') > -1 && timeString.toString()[0] !== '-') {
    timeString = timeString.replace(/-/g, "/")
  }
  const date = new Date(timeString)
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2,'0')
  const day = date.getDate().toString().padStart(2,'0')
  const hour = date.getHours().toString().padStart(2,'0')
  const minute = date.getMinutes().toString().padStart(2,'0')
  const second = date.getSeconds().toString().padStart(2,'0')
  return { year, month, day, hour, minute, second }
}