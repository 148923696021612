<template>
  <div class="nickname">
    <div class="title">
      <div @click="goBack">
        <img src="../imgs/back.png" alt="" />
        <p>{{ $route.meta.title === '修改密码' ? '个人中心' : '登录' }}</p>
      </div>
      <span>{{ $route.meta.title }}</span>
    </div>
    <div class="content">
      <div class="input">
        <span
          >请输入需要{{
            $route.name === 'ForgetPwd' ? '找回' : '修改'
          }}密码账号的手机号</span
        >
        <input
          type="text"
          oninput="value=value.replace(/[^\d]/g,'')"
          maxlength="11"
          placeholder="请输入"
          v-model="form.phone"
          @keyup.enter="sendMsg"
        />
      </div>

      <div class="input input2">
        <input
          type="text"
          maxlength="6"
          oninput="value=value.replace(/[^\d]/g,'')"
          v-model="form.verification_code"
          placeholder="请输入"
        />
        <div
          :style="{
            background: time ? '#999999' : '#259DFF',
          }"
          @click="sendMsg"
        >
          {{ time ? `${time}s后重试` : '获取验证码' }}
        </div>
      </div>

      <div class="pwd">
        <div class="input">
          <span>请输入新密码</span>
          <input
            type="password"
            v-model="form.new_password"
            placeholder="请输入"
          />
        </div>

        <div class="input">
          <span>确认密码</span>
          <input
            type="password"
            v-model="form.new_password_confirmation"
            placeholder="请输入"
            @keyup.enter="chagePwd"
          />
        </div>

        <div class="input tip">
          <span>密码长度8-16位，数字、字母、字符至少包含两种</span>
        </div>
      </div>

      <div class="save" @click="chagePwd">保存密码</div>
    </div>
  </div>
</template>

<script>
import request from '@/utils/request'
import { baseUrl } from '@/utils/config'
let msgTimer = null
const regPhone = /^1[3-9]\d{9}$/
const regPwd = /^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{8,16}$/
export default {
  data() {
    return {
      form: {
        phone: '',
        verification_code: '',
        verification_key: '',
        new_password: '',
        new_password_confirmation: '',
      },
      time: 0,
      token: this.$store.state.token,
      userInfo: JSON.parse(JSON.stringify(this.$store.state.userInfo)),
    }
  },
  methods: {
    async sendMsg() {
      try {
        if (this.time) {
          return
        }
        if (!this.form.phone) {
          this.$message.info('请输入手机号')
          return
        }
        if (!regPhone.test(this.form.phone)) {
          this.$message.info('手机号不合法')
          return
        }

        this.time = 60
        const res = await request({
          url: `${baseUrl}/api/sendSms`,
          method: 'post',
          headers: {
            'X-Requested-with': 'XMLHttpRequest',
          },
          data: {
            phone: this.form.phone,
          },
        })
        if (res.code === 200) {
          this.form.verification_key = res.data.key
          msgTimer = setInterval(() => {
            this.time--
            if (this.time <= 0) {
              clearInterval(msgTimer)
              msgTimer = null
            }
          }, 1000)
        }
      } catch (e) {
        console.log(e)
      }
    },
    async chagePwd() {
      if (!this.form.phone) {
        this.$message.info('请输入手机号')
        return
      }
      if (!regPhone.test(this.form.phone)) {
        this.$message.info('手机号不合法')
        return
      }
      if (!this.form.verification_code) {
        this.$message.info('请输入验证码')
        return
      }
      if (!this.form.new_password) {
        this.$message.info('请输入新密码')
        return
      }
      if (!this.form.new_password_confirmation) {
        this.$message.info('请输入确认密码')
        return
      }

      if (this.form.new_password_confirmation !== this.form.new_password) {
        this.$message.info('两次密码不一致')
        return
      }

      if (!regPwd.test(this.form.new_password)) {
        this.$message.info('密码长度8-16位，数字、字母、字符至少包含两种')
        return
      }

      if (
        this.form.new_password_confirmation.length < 8 ||
        this.form.new_password_confirmation.length > 16
      ) {
        this.$message.info('密码长度8-16位，不包含特殊字符')
        return
      }
      try {
        const res = await request({
          url: `${baseUrl}/api/${
            this.$route.name === 'ForgetPwd'
              ? 'find_password'
              : 'change_password'
          }`,
          method: 'post',
          headers: {
            Authorization: `Bearer ${this.token}`,
            'X-Requested-with': 'XMLHttpRequest',
          },
          data: this.form,
        })
        if (res.code === 200) {
          this.$message.success(
            this.$route.name === 'ChangePwd' ? '修改成功' : '找回成功'
          )
          this.form = {
            phone: '',
            verification_code: '',
            verification_key: '',
            new_password: '',
            new_password_confirmation: '',
          }
          this.time = 0
          clearInterval(msgTimer)
          msgTimer = null
          this.goBack()
        }
      } catch (e) {
        console.log(e)
        // this.$message.error(e.message || e.msg || '服务器错误')
      }
    },
    async getUserInfo() {
      try {
        const res = await request({
          url: `${baseUrl}/api/user_info`,
          method: 'get',
          headers: {
            Authorization: `Bearer ${this.token}`,
            'X-Requested-with': 'XMLHttpRequest',
          },
        })
        await this.$store.dispatch('getLogin', {
          access_token: this.token,
          user: res.data,
        })
        this.userInfo = res.data
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.nickname {
  height: 100%;
  background: rgba(245, 245, 245, 0.9);
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  flex-direction: column;

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    height: 116px;
    font-size: 32px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    position: relative;
    div {
      position: absolute;
      top: 0;
      padding-top: 10px;
      left: 60px;
      height: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
      img {
        margin-right: 12px;
        width: 12px;
        height: 23px;
      }
      p {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.25);
        line-height: 25px;
      }
    }
  }

  .content {
    margin: 45px auto;
    width: 370px;
    overflow: auto;
    .input {
      display: flex;
      flex-direction: column;
      span {
        padding: 0 16px;
        margin-bottom: 11px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
      }
      input {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        line-height: 20px;
        width: 100%;
        height: 44px;
        background: #ffffff;
        box-shadow: 0px 6px 7px -8px rgba(0, 0, 0, 0.3);
        border: 1px solid #259dff;
        border-radius: 12px;
        min-height: 100%;
        outline: none;
        padding: 0 16px;
        &::-webkit-input-placeholder {
          /* WebKit, Blink, Edge */
          color: #e0d8d8;
        }
        &:-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: #e0d8d8;
        }
        &::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: #e0d8d8;
        }
        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: #e0d8d8;
        }
      }
    }

    .input2 {
      margin-top: 24px;
      flex-direction: row;
      input {
        flex: 1;
        overflow: hidden;
      }
      div {
        margin-left: 16px;
        width: 101px;
        height: 44px;
        background: #259dff;
        border-radius: 12px;
        border: 1px solid rgba(255, 255, 255, 0.4);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        cursor: pointer;
      }
    }

    .pwd {
      padding-top: 16px;
      .input {
        margin-top: 12px;
      }
    }

    .save {
      margin-top: 40px;
      width: 100%;
      height: 44px;
      background: #259dff;
      border-radius: 12px;
      border: 1px solid rgba(255, 255, 255, 0.4);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 22px;
      cursor: pointer;
    }

    .tip {
      span {
        color: #999999;
      }
    }
  }
}
</style>
